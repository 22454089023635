// common router
// lazy-loadを使うとvuetifyでエラー
import Home from '@/views/Home.vue'
import Purchase from '@/commons/views/Purchase.vue'
import Contact from '@/commons/views/Contact.vue'
import Setting from '@/views/Setting.vue'
import Request from '@/commons/views/Request.vue'
import Review from '@/commons/views/Review.vue'
import Privacy from '@/commons/views/Privacy.vue'
import Admin from '@/views/Admin.vue'

export default [{
  path: '/',
  name: 'home',
  component: Home
}, {
  path: '/setting',
  name: 'setting',
  component: Setting
}, {
  path: '/purchase',
  name: 'purchase',
  component: Purchase
}, {
  path: '/contact',
  name: 'contact',
  component: Contact
}, {
  path: '/request',
  name: 'request',
  component: Request
}, {
  path: '/review',
  name: 'review',
  component: Review
}, {
  path: '/privacy',
  name: 'privacy',
  component: Privacy
}, {
  path: '/admin',
  name: 'admin',
  component: Admin
}, {
  path: '/ja/',
  name: 'home-ja',
  component: Home
}, {
  path: '/ja/setting',
  name: 'setting-ja',
  component: Setting
}, {
  path: '/ja/purchase',
  name: 'purchase-ja',
  component: Purchase
}, {
  path: '/ja/contact',
  name: 'contact-ja',
  component: Contact
}, {
  path: '/ja/request',
  name: 'request-ja',
  component: Request
}, {
  path: '/ja/review',
  name: 'review-ja',
  component: Review
}, {
  path: '/ja/privacy',
  name: 'privacy-ja',
  component: Privacy
}, {
  path: '/ja/admin',
  name: 'admin-ja',
  component: Admin
}, {
  path: '/en/',
  name: 'home-en',
  component: Home
}, {
  path: '/en/setting',
  name: 'setting-en',
  component: Setting
}, {
  path: '/en/purchase',
  name: 'purchase-en',
  component: Purchase
}, {
  path: '/en/contact',
  name: 'contact-en',
  component: Contact
}, {
  path: '/en/request',
  name: 'request-en',
  component: Request
}, {
  path: '/en/review',
  name: 'review-en',
  component: Review
}, {
  path: '/en/privacy',
  name: 'privacy-en',
  component: Privacy
}, {
  path: '/en/admin',
  name: 'admin-en',
  component: Admin
},
 // 外部Data読み込み用（必ず最後に読み込む）
{
  path: '/:id',
  name: 'home',
  component: Home
}, {
  path: '/ja/:id',
  name: 'home-ja',
  component: Home
}, {
  path: '/en/:id',
  name: 'home-en',
  component: Home
}]
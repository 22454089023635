<template>
  <fragment>
    
    <TheAppbar :title="$t('title')" />

    <v-main :style="{minHeight: this.bodyHeight + 'px'}">
      <v-container class="text">
        <p v-html="$t('desc')"></p>
        <TheForm page-type="request" />
        <v-divider></v-divider>
        <div class="answer" v-html="answer"></div>
      </v-container>
    </v-main>

  </fragment>
</template>

<script>
  import axios from 'axios'
  import TheAppbar from '@/commons/components/TheAppbar.vue'
  import TheForm from '@/commons/components/TheForm.vue'

  export default{
    data() {
      return {
        answer: '',
      }
    },
    async mounted() {
      if (this.lang === 'ja') {
        const res = await axios.get(this.INIT.URLS.request).catch((error) => { console.log(error) })
        if (_.has(res, 'data.content.rendered')) {
          this.answer = res.data.content.rendered
        }
      }
    },
    i18n: {
      messages: {
        ja: {
          title: '機能追加のご要望',
          desc: '「こんな機能があったらいいのに！」「ここが改善されるといいのに」といったご要望がありましたら、こちらのメールフォームよりご連絡ください。<br>ご要望の多い機能より随時対応いたします。',
        },
        en: {
          title: 'Requests',
          desc: 'Please send us your request.',
        }
      }
    },
    components: { TheForm, TheAppbar },
  }
</script>
<style scoped>
  .v-divider {
    margin: 20px 0;
  }
  .answer>>>h1 {
    font-size: 1.4em;
  }
  .answer>>>h2 {
    font-size: 1.2rem;
    margin: 30px 0 5px;
  }
  .answer>>>li {
    margin-bottom: 10px;
  }
</style>
<template>
  <fragment>
    <v-container fill-height :style="{paddingBottom: footerHeight + 'px'}">
      <v-subheader>{{ $t('title') }}</v-subheader>
      <v-list>
        <!-- 画面分割 -->
        <v-list-item
          @click="setInputDialog(radioSplit)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ radioSplit.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ _.find(radioSplit.options, ['id', split]).label }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- Map Type -->
        <v-list-item
          @click="setInputDialog(radioMapType)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ radioMapType.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ _.find(radioMapType.options, ['id', style]).label }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- お気に入り -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('favoriteTitle') }}</v-list-item-title>
            <v-list-item-subtitle>{{ showFavoriteMarkers ? '表示する' : '表示しない' }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="showFavoriteMarkers"></v-switch>
          </v-list-item-action>
        </v-list-item>

        <!-- 照準 -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('crosshairTitle') }}</v-list-item-title>
            <v-list-item-subtitle>{{ crosshair ? '表示する' : '表示しない' }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-switch v-model="crosshair"></v-switch>
          </v-list-item-action>
        </v-list-item>

        <!-- Theme -->
        <v-list-item
          @click="setInputDialog(radioTheme)"
        >
          <v-list-item-content>
            <v-list-item-title>{{ radioTheme.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ _.find(radioTheme.options, ['id', theme]).label }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-btn
        v-if="! isPurchased && this.tickets"
        block
        x-large
        color="primary"
        @click="openPurchase"
      >広告非表示のご案内</v-btn>

    </v-container>
    <TheInputDialog
      :inputDialog="inputDialog"
      @save="saveInput"
      @cancel="inputDialog = null" />
  </fragment>
</template>

<script>
  import TheInputDialog from '@/commons/components/TheInputDialog.vue'

  export default {
    components: { TheInputDialog },
    data() {
      return {
        // 対象データ
        inputDialog: null,
        value: null,
        // テーマ
        radioTheme: {
          title: this.$t('theme.theme'),
          type: 'radio',
          key: 'theme',
          value: null,
          options: [
            {id: 'light', label: this.$t('theme.light')},
            {id: 'dark', label: this.$t('theme.dark')},
            {id: 'system', label: this.$t('theme.system')}
          ],
        },
        // 画面分割
        radioSplit: {
          title: this.$t('splitTitle'),
          type: 'radio',
          key: 'split',
          value: null,
          options: [
            {id: 'vertical', label: this.$t('vertical')},
            {id: 'horizontal', label: this.$t('horizontal')},
          ],
        },
        // マップタイプ
        radioMapType: {
          title: this.$t('mapTypeTitle'),
          type: 'radio',
          key: 'style',
          value: null,
          options: [
            {id: 'roadmap', label: this.$t('roadmap')}, // 'road'ではなく'street'にすべき？
            {id: 'satellite', label: this.$t('satellite')},
            {id: 'hybrid', label: this.$t('hybrid')}
          ],
        },
      }
    },
    methods: {
      saveInput(res) {
        this[res.key] = res.value
        this.inputDialog = null
      },
      setInputDialog(inputDialog) {
        inputDialog.value = this?.[inputDialog.key] || null
        this.inputDialog = _.cloneDeep(inputDialog)
      },
      openPurchase() {
        this.pageId = 'purchase' // routerより先に変更しないとリアクティブにならない？
        setTimeout(() => {
          this.$router.push('/purchase').catch((err)=>{ console.log(err.message) })
          this.tabId = 'tab0'
        }, 0)
      }
    },
    i18n: {
      messages: {
        ja: {
          title: '設定',
          splitTitle: '画面分割',
          mapTypeTitle: 'マップタイプ（現在）',
          favoriteTitle: 'お気に入りマーカーの表示/非表示',
          crosshairTitle: '照準の表示/非表示'
        },
        en: {
          title: 'Setting',
          splitTitle: 'Split map',
          mapTypeTitle: 'Current Map type',
          favoriteTitle: 'Favorite Markers',
          crosshairTitle: 'Crosshair'
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: calc(var(--safe-area-top) + 20px);
  }
  .v-subheader {
    font-size: 24px;
  }
  .v-list-item__content {
    padding: 16px 0;
  }
  .v-list-item__subtitle {
    font-size: 1rem;
  }
</style>

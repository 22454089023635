<template>
  <fragment>
    <TheAppbar title="ダッシュボード"></TheAppbar>
    <v-main>
      <v-container>
        <v-card
          flat
          color="transparent"
        >
          <v-card-title>タイル探索＆ダウンロード</v-card-title>
          <v-card-text>
            <v-radio-group 
              v-model="targetZoom"
              row
            >
              <v-radio
                v-for="zoom in [10, 11, 12]"
                :key="zoom"
                :label="_.toString(zoom)"
                :value="zoom"
                on-icon="radio_button_checked"
                off-icon="radio_button_unchecked"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              @click="search"
            ><v-icon color="primary">{{ isSearching ? 'stop' : 'play_arrow' }}</v-icon></v-btn>
          </v-card-actions>
        </v-card>
        <v-card>
          <v-progress-linear
            v-show="progress"
            height="20"
            :value="progress"
            striped
            rounded
          ></v-progress-linear>
        </v-card>
      </v-container>
    </v-main>
  </fragment>
</template>

<script>
  import BASE_LAYERS from '@/jsons/base_layers.json'
  import LAYERS9 from '@/jsons/layers9.json'
  import LAYERS10 from '@/jsons/layers10.json'
  import LAYERS11 from '@/jsons/layers11.json'
  import LAYERS12 from '@/jsons/layers12.json'
  import BASE_TILES from '@/jsons/base-tiles.json'
  import TheAppbar from '@/commons/components/TheAppbar.vue'

  export default {
    components: { TheAppbar },
    data() {
      return {
        isSearching: false,
        progress: 0,
        targetZoom: 10,
        layers9: LAYERS9,
        layers10: LAYERS10,
        layers11: LAYERS11,
        layers12: LAYERS12,
      }
    },
    methods: {
      async search() {
        this.isSearching = true
        const layers = BASE_LAYERS
        let done = 0
        for (const layer of layers) {
          if (layer.age === 76) { done++; continue }
          const exist = []
          const checked = []
          const targetTiles = this.getTargetTiles(layer.age)
          for (const targetTile of targetTiles) {
            if (this.isExist(layer, targetTile, this.targetZoom)) {
              console.log('exist')
              exist.push(targetTile)
            }
            // duplicate
            this.checkDuplicate(checked, targetTile)
            await this.sleep(200)
          }
          console.log(exist, checked)
          layer.tiles = exist
          // progress
          done++
          this.progress = (done / layers.length) * 100
        }
        this.download('layers', layers)
        this.isSearching = false
      },
      // 二重チェック確認
      checkDuplicate(checked, tile) {
        const tileStr = JSON.stringify(tile)
        if (_.includes(checked, tileStr)) {
          console.log('Duplicated!!!')
        }
        checked.push(tileStr)
      },
      // 探索対象タイル一覧
      getTargetTiles(age) {
        const lowerZoomLayers = this['layers' + (this.targetZoom - 1)]
        const lowerZoomTiles = _.find(lowerZoomLayers, ['age', age]).tiles
        const tiles = lowerZoomTiles.length ? _.flatten(_.map(lowerZoomTiles, tile => {
          return this.getNextZoomTiles(tile)
        })) : BASE_TILES
        const allTargetTiles = _.flatten(_.map(tiles, tile => {
          return this.getSurroundTiles(tile)
        }))
        const targetTiles = []
        _.forEach(allTargetTiles, allTargetTile => {
          let exist = false
          _.forEach(targetTiles, targetTile => {
            if (allTargetTile.x === targetTile.x && allTargetTile.y === targetTile.y) {
              exist = true
              return false
            }
          })
          if (! exist) {
            targetTiles.push(allTargetTile)
          }
        })
        return targetTiles
      },
      // 画像存在確認
      isExist(layer, targetTile, zoom) {
        const url = 'https://cyberjapandata.gsi.go.jp/xyz/' + layer.dir + '/' + zoom + '/' + targetTile.x + '/' + targetTile.y + '.' + layer.ext
        const xhr = new XMLHttpRequest()
        xhr.open('HEAD', url, false)
        xhr.send(null)
        return xhr.status === 200 ? true : false
      },
      // 詳細タイル一覧
      getNextZoomTiles(tile) {
        return [
          {x: tile.x * 2, y: tile.y * 2},
          {x: (tile.x * 2) - 1, y: tile.y * 2},
          {x: tile.x * 2, y: (tile.y * 2) - 1},
          {x: (tile.x * 2) - 1, y: (tile.y * 2) - 1},
        ]
      },
      // 周辺タイル一覧
      getSurroundTiles(tile) {
        return [
          {x: tile.x, y: tile.y}, {x: tile.x, y: tile.y+1}, {x: tile.x, y: tile.y-1},
          {x: tile.x+1, y: tile.y}, {x: tile.x+1, y: tile.y+1}, {x: tile.x+1, y: tile.y-1},
          {x: tile.x-1, y: tile.y}, {x: tile.x-1, y: tile.y+1}, {x: tile.x-1, y: tile.y-1}
        ]
      },
      // ダウンロード
      download(name, data) {
        const blob = new Blob([JSON.stringify(data)], {type: 'application/json'})
        const a = document.createElement('a')
        a.download = name + '.json'
        a.href = URL.createObjectURL(blob)
        a.click()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    margin-bottom: 24px;
  }
</style>

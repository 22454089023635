<template>
  <div></div>
</template>

<script>
  import Place from '@/modules/place.js'
  export default {
    props: ['map', 'isReady'],
    data() {
      return {
        selectedMarker: null,
        favoritesMarkers: [],
      }
    },
    computed: {},
    mounted() {},
    watch: {
      isReady() {
        this.addFavoritesMarkers()
      },
      // favorite markers
      favorites() {
        this.addFavoritesMarkers()
      },
      showFavoriteMarkers() {
        this.addFavoritesMarkers()
      },
      // selected marker
      selectedPlace() {
        if (! this.selectedPlace || this.selectedPlace.isSaved) {
          this.map.removeLayer(this.selectedMarker)
        } else {
          if (! _.find(this.favorites, ['id', this.selectedPlace.id])) { // not from favorite
            this.showFavoriteMarkers = true
            if (this.selectedMarker) {
              this.map.removeLayer(this.selectedMarker)
            }
            this.selectedMarker = this.map.addSelectedMarker(this.selectedPlace.position)
          }
        }
      },
    },
    methods: {
      // favorite marker追加
      addFavoritesMarkers() {
        if (this.isReady) {
          this.map.removeLayers(this.favoritesMarkers)
          if (this.showFavoriteMarkers) {
            _.forEach(this.favorites, favorite => {
              this.favoritesMarkers.push(this.map.addFavoriteMarker(favorite.position, async () => {
                this.selectedPlace = await Place.init(favorite, this.style)
              }))
            })
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>

<template>
  <fragment>
    <transition name="fade">
      <v-speed-dial
        v-model="fab"
        absolute
        bottom left
        v-show="showUI"
        :class="{'low-height': bodyHeight < 576}"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            fab
            :ripple="false"
            color="primary"
          >
            <v-icon v-if="fab">close</v-icon>
            <span v-else>{{ age }}</span>
          </v-btn>
        </template>
        <v-btn
          fab
          :small="layer.age !== 76"
          v-for="layer in layers"
          :key="layer.age"
          :class="{disabled: ! layer.isExist}"
          :color="age === layer.age ? 'primary' : null"
          @click="click(layer)"
        ><span>{{ layer.age }}</span></v-btn>
      </v-speed-dial>
    </transition>

    <v-snackbar
      v-model="snackbar"
      top
    >
      {{ $t('noImage') }}
    </v-snackbar>
  </fragment>
</template>

<script>
  import LAYERS12 from '@/jsons/layers12.json'

  export default {
    data() {
      return {
        layers: LAYERS12,
        fab: false,
        snackbar: false,
        timeout: null,
      }
    },
    watch: {
      currentCenter() {
        if (this.fab) {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.checkExist()
          }, 1000)
        }
      },
      fab() {
        if (this.fab) {
          this.checkExist()
        }
      }
    },
    methods: {
      // 年代変更
      click(layer) {
        this.age = layer.age
      },
      // 年代タイル存在確認
      checkExist() {
        _.forEach(this.layers, async layer => {
          if (layer.age !== 76) {
            layer.isExist = this.isExistOnData(layer)
          } else {
            layer.isExist = true
          }
        })
      },
      // タイルの存在確認（データから）
      isExistOnData(layer) {
        const targetTile = this.getTiles(this.currentCenter, 12)
        const tileStrs = _.map(layer.tiles, tile => { return JSON.stringify(tile) })
        return _.includes(tileStrs, JSON.stringify(targetTile))
      },
      // 特定のズームに対するタイル番号
      getTiles(coords, zoom) {
        return {
          x: Math.floor((coords.lng + 180) / 360 * Math.pow(2, zoom)),
          y: Math.floor((1 - Math.log(Math.tan(coords.lat * Math.PI / 180) + 1 / Math.cos(coords.lat * Math.PI / 180)) / Math.PI) / 2 * Math.pow(2, zoom)),
        };
      },
    },
    i18n: {
      messages: {
        ja: {
          noImage: 'この地域ではその年代に対応していません',
        },
        en: {
          noImage: 'There is no old satellite images.',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-btn {
    span {
      font-size: 1.2em;
    }
    &.disabled {
      opacity: .7;
      text-decoration: line-through;
      span {
        opacity: .7;
      }
    }
  }
  .v-speed-dial.low-height {
    left: calc(16px + var(--safe-area-left));
    ::v-deep .v-speed-dial__list {
      padding: 8px;
      &>div:nth-child(4),
      &>div:nth-child(5),
      &>div:nth-child(6) {
        position: relative;
        top: 150px;
        left: 60px;
      }
    }
  }
  /* 全画面サイズなのでポインター透過 */
  .v-snack {
    pointer-events: none !important;
    top: calc(var(--safe-area-top) + 80px);
  }
</style>

<template>
  <div
    id="map-uis"
    :style="style"
  >
    <Toolbar />
    <FabLocation />
    <DialAges />
  </div>
</template>

<script>
  import Toolbar from '@/components/UIsToolbar.vue'
  import FabLocation from '@/components/UIsFabLocation.vue'
  import DialAges from '@/components/UIsDialAges.vue'
  export default {
    components: { Toolbar, FabLocation, DialAges },
    computed: {
      style() {
        return {
          top: this.safeArea.top ? this.safeArea.top + 6 + 'px' : 28 + 'px',
        }
      }
    },
  }
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>

<style lang="scss" scoped>
  #map-uis {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    pointer-events: none;
    ::v-deep * {
      pointer-events: auto;
    }
  }
</style>

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import _i18n from '@/commons/modules/_i18n'
import _mixin from '@/commons/modules/_mixin'

Vue.use(VueI18n)

export default new VueI18n({
  locale: _mixin.computed.lang(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  messages: {
    ja: Object.assign(_i18n.ja, {
      appName: '昔の航空写真地図',
      appTitle: '昔の航空写真地図 | 1970年代の航空写真を表示するアプリ',
      appDesc: '1970年代の航空写真を表示するアプリです',
      // map
      roadmap: '地図',
      satellite: '航空写真',
      hybrid: '航空写真（ラベル付き）',
      vertical: '左右に並べる',
      horizontal: '上下に並べる',
      current: '現在',
      past: '過去', // 未使用
      // 機能
      save: '保存',
      detail: '詳細',
      gmap: 'マップ',
      share: 'シェア',
      post: '投稿',
      download: 'ダウンロード',
      // お気に入り
      tag: 'タグ（カテゴリー）',
      label: 'ラベル',
      address: '住所',
      desc: 'メモ',
      coordinates: '座標',
  }),
    en: Object.assign(_i18n.en, {
      appName: 'Old Satellite Map JAPAN',
      appTitle: 'Old Satellite Map JAPAN | App that displays aerial photographs of the 1970s',
      appDesc: 'App that displays aerial photographs of the 1970s in Japan.',
      // map
      roadmap: 'Road Map',
      satellite: 'Satellite',
      hybrid: 'Satellite (with label)',
      vertical: 'Vertical',
      horizontal: 'Horizontal',
      current: 'Current',
      past: 'Past', // 未使用
      // 機能
      save: 'save',
      detail: 'detail',
      gmap: 'map',
      share: 'share',
      post: 'post',
      download: 'download',
      // お気に入り
      tag: 'Tags',
      label: 'Label',
      address: 'Address',
      desc: 'Memo',
      coordinates: 'Coordinates',
    }),
  }
})

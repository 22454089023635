<template>
  <fragment>
    <v-dialog
      v-model="dialog"
      fullscreen
      origin="center bottom"
    >
      <v-card v-if="newFavorite">
        <v-toolbar
          :height="appBarHeight"
        >
          <v-btn
            icon
            @click="close"
          ><v-icon>expand_more</v-icon></v-btn>
          <v-toolbar-title>{{ newFavorite.name }}</v-toolbar-title>
        </v-toolbar>
        <v-main>
          <v-img>
            <img v-if="newFavorite.dataURL" :src="newFavorite.dataURL">
            <v-progress-circular
              v-else
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-img>
          <v-list>
            <v-btn
              fab
              top right
              absolute
              large
              class="age"
            >{{ newFavorite.age }}</v-btn>
            <v-list-item @click="setInputDialog('text', 'name', $t('label'))">
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('label') }}</v-list-item-subtitle>
                <v-list-item-title>{{ newFavorite.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item @click="setInputDialog('combobox', 'tags', $t('tag'))">
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('tag') }}</v-list-item-subtitle>
                <v-list-item-title>
                  <span
                    v-for="tag in newFavorite.tags"
                    :key="tag"
                    :class="['v-chip', {'theme--dark': isThemeDark}, {'theme--light': ! isThemeDark}]"
                  >{{ tag }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item @click="setInputDialog('text', 'address', $t('address'))">
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('address') }}</v-list-item-subtitle>
                <v-list-item-title>{{ newFavorite.address }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item @click="setInputDialog('text', 'desc', $t('desc'))">
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('desc') }}</v-list-item-subtitle>
                <v-list-item-title>{{ newFavorite.desc }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>{{ $t('coordinates') }}</v-list-item-subtitle>
                <v-list-item-title>{{ _.round(favorite.position.lat, 5) }}, {{ _.round(favorite.position.lng, 5) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
          </v-list>
          <v-card-actions>
            <v-btn
              v-if="! isNew"
              outlined
              color="warning"
              @click="remove"
            >{{ $t('dialog.delete') }}</v-btn>
            <v-spacer />
            <v-btn
              outlined
              color="#AAAAAA"
              @click="close"
            >{{ $t('dialog.close') }}</v-btn>
            <v-btn
              outlined
              color="primary"
              @click="save"
            >{{ $t('dialog.save') }}</v-btn>
          </v-card-actions>
        </v-main>
      </v-card>
    </v-dialog>
    <TheInputDialog
      :inputDialog="inputDialog"
      @save="saveInput"
      @cancel="inputDialog = null" />
  </fragment>

</template>

<script>
  import { nanoid } from 'nanoid'
  import TheInputDialog from '@/commons/components/TheInputDialog.vue'
  import Place from '@/modules/place.js'

  export default {
    components: { TheInputDialog },
    data() {
      return {
        newFavorite: null,
        inputDialog: null,
        isNew: false, // 新規
      }
    },
    computed: {
      dialog() { return this.newFavorite ? true : false },
      tagOptions() { return _.uniq(_.flatten(_.map(this.favorites, 'tags'))) }
    },
    watch: {
      async favorite() {
        if (this.favorite) {
          this.newFavorite = await Place.init(this.favorite, this.style)
          this.isNew = _.find(this.favorites, ['id', this.favorite.id]) ? false : true
          // 詳細追加
          if (! this.newFavorite.placeId) {
            const detail = await this.newFavorite.getDetail()
            this.newFavorite = _.assign(this.newFavorite, detail)
          }
          // サムネイル追加
          if (! this.newFavorite.dataURL) {
            this.capturePlace = this.newFavorite
          }
        } else {
          this.newFavorite = null
          this.capturePlace = null
        }
      },
      // サムネイル追加
      captureDataURL() {
        if (this.captureDataURL) {
          if (this.favorite) {
            this.newFavorite.dataURL = this.captureDataURL
            this.capturePlace = null
            this.captureDataURL = null
            // this.update()
          }
        }
      }
    },
    methods: {
      // save input dialog
      saveInput(res) {
        this.newFavorite[res.key] = res.value
        this.inputDialog = null
      },
      // set input dialog
      setInputDialog(type, key, title) {
        this.inputDialog = {
          type: type,
          title: title,
          key: key,
          value: this.newFavorite[key],
          options: type === 'combobox' ? this.tagOptions : null
        }
      },
      // アップデート（いろいろ怪しい）
      // async update() {
      //   if (! this.isNew) {
      //     this.newFavorite.fileURI = await this.newFavorite.getFileURI()
      //     const favorites = _.cloneDeep(this.favorites)
      //     const favoriteIndex = _.findIndex(favorites, ['id', this.favorite.id])
      //     this.newFavorite.id = _.isString(this.newFavorite.id) ? this.newFavorite.id : nanoid(10)
      //     favorites[favoriteIndex] = this.newFavorite.getSaveData()
      //     this.favorites = favorites
      //   }
      // },
      // 保存
      async save() {
        this.newFavorite.fileURI = await this.newFavorite.getFileURI()
        const favorites = _.filter(this.favorites, favorite => { return favorite.id !== this.newFavorite.id})
        this.newFavorite.id = _.isString(this.newFavorite.id) ? this.newFavorite.id : nanoid(10)
        this.favorites = _.concat([this.newFavorite.getSaveData()], favorites)
        this.selectedPlace = this.selectedPlace ? _.assign(_.cloneDeep(this.selectedPlace), {isSaved: true}) : null
        this.favorite = null
      },
      // 削除
      remove() {
        this.newFavorite.deleteFile()
        const favorites = _.filter(this.favorites, favorite => { return favorite.id !== this.newFavorite.id})
        this.favorites = _.cloneDeep(favorites)
        this.favorite = null
        this.selectedPlace = null
      },
      // 閉じる
      close() {
        if (this.isNew) {
          this.newFavorite.deleteFile()
        }
        this.favorite = null
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-toolbar {
    ::v-deep .v-toolbar__content {
      padding-top: var(--safe-area-top);
    }
  }
  .v-dialog__content {
    z-index: 9999 !important;
  }
  .v-card {
    padding-bottom: 80px !important;
  }
  .v-main {
    max-width: 840px;
    margin: 0 auto;
  }
  .v-image {
    margin-bottom: 12px;
    ::v-deep .v-responsive__content {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      aspect-ratio: 800 / 450;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .v-list {
    position: relative;
  }
  .age {
    ::v-deep {
      font-size: 1.2em;
    }
  }
  .v-list-item {
    padding: 0 4%;
  }
  .v-list-item__title {
    span {
      height: 32px;
      font-size: 14px;
      border-radius: 16px;
      margin: 4px 8px 0 0;
    }
  }
  .v-divider {
    margin: 0 4%;
  }
  .v-card__actions {
    padding: 12px 4%;
  }
</style>

<template>
  <fragment>
    
    <TheAppbar :title="$t('title')"></TheAppbar>
    
    <v-main>
      <v-list>
        <v-list-item>
          <v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-main>

  </fragment>
</template>

<script>
  import TheAppbar from '@/commons/components/TheAppbar.vue'
  
  export default {
    components: { TheAppbar },
    data() {
      return {}
    },
    i18n: {
      messages: {
        ja: {
          title: '設定',
        },
        en: {
          title: 'Setting',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>

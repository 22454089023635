<template>
  <div id="map-canvas" :style="{opacity: opacity}">
    <div id="map-l"></div>
    <div id="map-n">
      <img :src="_.get(capturePlace, 'googleSrc')">
    </div>
  </div>
</template>

<script>
  import { nanoid } from 'nanoid'
  import L from '@/modules/leaflet.js'
  import html2canvas from 'html2canvas'
  export default {
    data() {
      return {
        mapL: null,
        captureId: null,
      }
    },
    computed: {
      opacity() {
        return this.capturePlace ? 1 : 0
      },
    },
    mounted() {
      // leaflet
      this.mapL = L.draw({
        id: 'map-l',
      })
      this.mapL.addGSILayer(this.age)
    },
    watch: {
      capturePlace() {
        if (this.capturePlace) {
          this.captureDataURL = null
          this.captureId = nanoid(10)
          this.mapL.setView(this.capturePlace.position, this.capturePlace.zoom + 1, {animate: false})
          setTimeout(() => {
            this.mapL.addGSILayer(this.capturePlace.age, this.captureId, this.capture)
          }, 500)
        }
      },
    },
    methods: {
      capture(callbackId) {
        if (callbackId === this.captureId) {
          setTimeout(() => {
            html2canvas(document.getElementById('map-canvas'), {
              allowTaint: true,
              useCORS: true,
              scale: 1,
            }).then(canvas => {
              this.captureDataURL = canvas.toDataURL('image/jpeg', 0.8)
            })
          }, 1000)
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  #map-canvas {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    height: 450px;
    width: 800px;
    z-index: -1;
  }
  #map-l {
    width: 50%;
    height: 100%;
  }
  #map-n {
    width: 50%;
    height: 100%;
  }
</style>

<template>
  <fragment>
    <div
      id="map1"
      :style="{background: isThemeDark ? '#121212' : '#F5F5F5'}"
    ></div>
    <Markers :map="map" :isReady="isReady" />
  </fragment>
</template>

<script>
  import { ResizeObserver } from 'resize-observer'
  import L from '@/modules/leaflet.js'
  import Place from '@/modules/place.js'
  import Markers from '@/components/Map1Markers.vue'

  export default {
    components: { Markers },
    data() {
      return {
        mapId: 'map1',
        map: null,
        isReady: false,
        resizeTimeout: null,
        resizeObserver: null,
      }
    },
    computed: {
      isPrimary() { return this.primaryMap === this.mapId },
    },
    mounted() {
      setTimeout(() => {
        this.map = L.draw({
          id: this.mapId,
          center: this.currentCenter,
          zoom: this.currentZoom
        })
        this.map.addGSILayer(this.age)
        this.addEvent()
        this.observeResize()
        this.isReady = true
      }, 500)
    },
    watch: {
      // 地図移動
      currentCenter() {
        if (! this.isPrimary) {
          this.map.setView(this.currentCenter, this.currentZoom, {animate: false})
        }
      },
      // ズーム変更
      currentZoom() {
        if (! this.isPrimary) {
          this.map.setZoom(this.currentZoom)
        }
      },
      // 年代変更
      age() {
        this.map.addGSILayer(this.age)
      },
    },
    methods: {
      observeResize() {
        new ResizeObserver(() => {
          if (document.getElementById(this.mapId)) { // 別ページ表示時のエラー対策
            clearTimeout(this.resizeTimeout)
            this.resizeTimeout = setTimeout(() => {
              this.map.refresh()
            }, 500)
          }
        }).observe(document.getElementById(this.mapId))
      },
      addEvent() {
        // drag
        this.map.on('drag zoom', () => {
          this.primaryMap = this.mapId
          this.currentCenter = this.map.getCenter()
          this.currentZoom = _.round(this.map.getZoom())
        })
        this.map.on('dragend', () => {
          this.primaryMap = null
        })
        if (! this.isSite) {
          // click
          this.map.onClick(() => {
            if (this.selectedPlace) {
              this.selectedPlace = null
            } else {
              this.showUI = ! this.showUI
            }
          })
          // long press
          this.map.onContextmenu(async e => {
            this.showUI = true
            this.selectedPlace = await Place.init({
              position: e.latlng,
              zoom: this.currentZoom,
              age: this.age,
            }, this.style)
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  #map1 {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
</style>

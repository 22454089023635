<template>
  <transition name="fade">
    <v-btn
      id="target"
      fab absolute
      draggable
      :x-large="! (targetPosition.y === 50 && targetPosition.x === 50)"
      v-show="showUI"
      @click="click"
      @dragstart="dragstart"
      @drag="drag"
      @dragend="dragend"
      @touchstart="dragstart"
      @touchmove="drag"
      @touchend="dragend"
      @touchcancel="dragend"
      :style="targetStyle"
      :ripple="false"
    >
      <v-icon
        color="primary"
        large
        :style="{transform: isVertical ? 'rotate(90deg)' : 'rotate(0)'}"
      >unfold_more</v-icon>
    </v-btn>
  </transition>
</template>

<script>
  export default {
    data() {
      return {
        isTargetSwiping: false,
      }
    },
    computed: {
      // ターゲット位置補正
      targetStyle() {
        return {
          top: this.targetPosition.y === 0 ? this.safeArea.top + 75 + 'px'
                : this.targetPosition.y === 100 ? 'calc(100% - ' + this.bannerSize.height + 'px)'
                : this.targetPosition.y + '%',
          left: this.targetPosition.x + '%',
        }
      }
    },
    methods: {
      dragstart() {
        this.isTargetSwiping = true
      },
      drag(e) {
        if (this.isTargetSwiping) {
          const page = this.getPageXY(e)
          const position = this.getPosition(page.x, page.y)
          if (this.isVertical) {
            this.targetPosition = {
              x: (position.x < 10) ? 0 : (position.x > 90) ? 100 : position.x,
              y: 50,
            }
          } else {
            this.targetPosition = {
              x: 50,
              y: (position.y < 10) ? 0 : (position.y > 90) ? 100 : position.y,
            }
          }
        }
      },
      dragend(e) {
        const page = this.getPageXY(e)
        this.targetPosition = this.getPosition(page.x, page.y)
        this.targetPosition = {
          x: (this.targetPosition.x < 25) ? 0 : (this.targetPosition.x > 75) ? 100 : 50,
          y: (this.targetPosition.y < 25) ? 0 : (this.targetPosition.y > 75) ? 100 : 50,
        }
        this.isTargetSwiping = false
        this.currentCenter = _.cloneDeep(this.currentCenter) // マップ中央をリセット
      },
      click() {
        this.targetPosition = { x: 50, y: 50 }
        this.isTargetSwiping = false
      },
      // get pageXY according to 'drag' or 'touch'
      getPageXY(e) {
        return {
          x: e?.changedTouches?.[0]?.pageX || e.pageX,
          y: e?.changedTouches?.[0]?.pageY || e.pageY,
        }
      },
      // position in %
      getPosition(x, y) {
        return {
          x: _.round((x / this.bodyWidth) * 100, 2),
          y: _.round((y / this.bodyHeight) * 100, 2),
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  #target {
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
</style>

// place detail
import INIT from '@/init.js'
import Places from '@/commons/modules/places'
import { nanoid } from 'nanoid'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Capacitor } from '@capacitor/core'
import { Share } from '@capacitor/share'
import Storage from '@/commons/modules/storage.js'
import Firestore from '@/commons/modules/firestore'

export default class Place {
  constructor(place, maptype) {
    // 保存データ
    this.id = place.id || nanoid(10)
    this.placeId = place.placeId || ''
    this.name = place.name || 'マイプレイス'
    this.address = place.address || ''
    this.addressName = place.addressName || ''
    this.position = place.position || {lat: 0, lng: 0}
    this.zoom = place.zoom || 15
    this.age = place.age || 76
    this.desc = place.desc || ''
    this.fileURI = place.fileURI || ''
    this.tags = place.tags || []
    // 一時データ
    this.isSaved = place.id ? true : false
    this.dataURL = place.dataURL || ''
    this.fileName = this.id + '.jpg'
    this.mapURL = 'https://www.google.com/maps/@?api=1&map_action=map&center= ' + this.position.lat+ ',' + this.position.lng + '&zoom=' + this.zoom + '&basemap=satellite'
    this.googleSrc = 'https://maps.googleapis.com/maps/api/staticmap?format=png32&maptype=' + maptype + '&center=' + this.position.lat + ',' + this.position.lng + '&zoom=' + (this.zoom + 1) + '&size=400x450&key=' + INIT.API_KEY.gcp.web
    // this.mapboxSrc = 'https://api.mapbox.com/styles/v1/shiggy/cl11eya8c002z15qdymwphyrq/static/' + this.position.lng + ',' + this.position.lat + ',' + this.zoom + ',0/400x450?access_token=' + INIT.API_KEY.mapbox
    this.saveKeys = ['id', 'placeId', 'name', 'address', 'addressName', 'position', 'zoom', 'age', 'desc', 'fileURI', 'tags']
    if (Capacitor.getPlatform() === 'web') {
      this.saveKeys.push('dataURL')
    }
  }
  // constructorではasync使えないのでinitilizeを別で用意
  static async init(place, maptype) {
    maptype = maptype || 'satellite'
    const newPlace = new Place(place, maptype)
    newPlace.dataURL = newPlace.dataURL || await newPlace.readFile()
    return newPlace
  }
  // 保存データ
  getSaveData() {
    const saveData = {}
    _.forEach(this.saveKeys, key => {
      saveData[key] = this[key]
    })
    return saveData
  }
  // 詳細追加
  async getDetail() {
    const placeDetail = await Places.getPlaceByLatlng(this.position)
    return placeDetail.placeId ? {
      placeId: placeDetail.placeId,
      address: this.getDisplayAddress(placeDetail.formattedAddress),
      addressName: this.getShareAddress(placeDetail.addressComponents)
    } : {}
  }
  // サムネイル保存
  async getFileURI(directory) {
    directory = directory || 'Library'
    const path = await Filesystem.writeFile({
      path: this.fileName,
      data: this.dataURL,
      directory: Directory[directory],
    }).catch(err => { console.log(err) })
    return path.uri
  }
  // サムネイル読み込み
  async readFile() {
    const res = await Filesystem.readFile({
      path: this.fileName,
      directory: Directory.Library,
    }).catch(err => { console.log(err) })
    return res && res.data ? 'data:image/jpeg;base64,' + res.data : null
  }
  // サムネイル削除
  deleteFile() {
    Filesystem.deleteFile({
      path: this.fileName,
      directory: Directory.Library,
    }).catch(err => { console.log(err) })
  }
  // ダウンロード
  async download(platform) {
    const fileURI = await this.getFileURI('Cache')
    if (platform !== 'web') {
      // native
      Share.share({
        title: this.fileName,
        url: fileURI,
      })
    } else {
      // web
      let downloadEle = document.createElement('a')
      downloadEle.href = this.dataURL
      downloadEle.download = this.fileName
      downloadEle.click()
    }
  }
  // シェア
  async share(appName, userId) {
    await Storage.putDataURL('shares/' + this.fileName, this.dataURL)
    await Firestore.set('shares', {
      placeId: this.placeId,
      shareId: this.id,
      userId: userId,
      position: {lat: this.position.lat, lng: this.position.lng},
      zoom: this.zoom,
      age: this.age,
      address: this.address,
      addressName: this.addressName,
      hits: 0,
      createdAt: Firestore.getServerTimestamp(),
      UpdatedAt: Firestore.getServerTimestamp()
    }, this.id)
    return await Share.share({
      title: appName,
      text: '#昔の航空写真地図',
      url: INIT.URLS.site + '/share/' + this.id,
      dialogTitle: appName,
    }).catch(err => { console.log(err) })
  }
  // 表示住所取得 (addressComponentsは番地の並びが不完全なので使わない)
  getDisplayAddress(address) {
    return address ? address.replace('日本、', '').replace(' 日本', '').replace('〒', '').replace(/[0-9]{3}-[0-9]{4}/, '').replace(' ', '') : ''
  }
  // シェア住所取得
  getShareAddress(addressComponents) {
    const addressArray = []
    _.forEach(addressComponents, address => {
      if (! _.includes(address.types, 'plus_code') && ! _.includes(address.types, 'establishment') && ! _.includes(address.types, 'premise') && ! _.includes(address.types, 'sublocality') && ! _.includes(address.types, 'country') && ! _.includes(address.types, 'postal_code') ) {
        addressArray.push(address.long_name)
      }
    })
    return _.join(_.reverse(addressArray), '')
  }
}

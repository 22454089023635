<template>
  <fragment>
    <TheAppbar :title="$t('title')" />
    <v-main :style="{minHeight: this.bodyHeight + 'px'}">
      <v-container class="text">
        <p>{{ $t('desc1') }}</p>
        <p>{{ $t('desc2') }}</p>
        <p v-if="$t('desc3')">{{ $t('desc3') }}</p>
        <v-btn
          block
          color="primary"
          x-large
          @click="toStore"
        >
          <div>{{ $t('button') }}</div>
          <div>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
          </div>
        </v-btn>
      </v-container>
    </v-main>
  </fragment>
</template>

<script>
  import TheAppbar from '@/commons/components/TheAppbar.vue'
  export default{
    components: { TheAppbar },
    data() {
      return {};
    },
    methods: {
      toStore() {
        const scheme = (this.userAgent === 'android') ? this.INIT.URLS.play : this.INIT.URLS.app
        window.open(scheme, '_system')
      },
    },
    i18n: {
      messages: {
        ja: {
          title: '評価のお願い',
          desc1: 'アプリのご利用、ありがとうございます。',
          desc2: 'もしこのアプリを気に入ったいただけたら、評価をしていただけるとアプリ開発の励みにもなります。',
          desc3: 'どうぞよろしくお願いいたします。',
          button: '評価して応援する'
        },
        en: {
          title: 'REVIEW',
          desc1: 'Thank you for using this app.',
          desc2: 'If you like this app, please rate it, it will encourage me to develop this app.',
          desc3: '',
          button: 'Rate it to support us'
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  .text {
    padding-top: 40px;
  }
  .v-btn {
    margin-top: 32px;
    height: auto !important;
    padding: 10px 0 !important;
    ::v-deep .v-btn__content {
      flex-direction: column;
    }
  }
</style>

/* global */

import INIT from '@/init.js'
import { Capacitor } from '@capacitor/core'
import { CapacitorGoogleMaps } from '@capacitor-community/google-maps'

export default class NativeMaps {
  constructor() {
    this.map = null
    this.isReady = false
  }
  async draw(options) {
    options = Object.assign({
      id: 'map',
      zoom: 15,
      center: {lat: 35.7052579, lng: 139.752536},
      style: 'hybrid'
    }, options)
    await CapacitorGoogleMaps.initialize({
      key: INIT.API_KEY.gcp[Capacitor.getPlatform()],
      devicePixelRatio: window.devicePixelRatio,
    })
    this.map = await this.createMap(options)
    this.isReady = true
    return this.map
  }
  panTo(center) {
    if (! this.map) { return }
    CapacitorGoogleMaps.moveCamera({
      mapId: this.map.googleMap.mapId,
      cameraPosition: {
        target: {
          latitude: center.lat,
          longitude: center.lng,
        },
      },
    })
  }
  setZoom(zoom) {
    if (! this.map) { return }
    CapacitorGoogleMaps.moveCamera({
      mapId: this.map.googleMap.mapId,
      cameraPosition: {
        zoom: zoom
      },
    })
  }
  setMapType(style) {
    if (! this.map) { return }
    CapacitorGoogleMaps.updateMap({
      mapId: this.map.googleMap.mapId,
      preferences: { appearance: { type: this.getMapType(style) } }
    })
  }
  async refresh(options) {
    if (! this.map) { return }
    await CapacitorGoogleMaps.removeMap({mapId: this.map.googleMap.mapId}).catch(err => { console.log('remove err', err) })
    this.map = await this.createMap(options)
  }
  async createMap(options) {
    const boundingRect = this.getBoundingRect(options.id)
    return await CapacitorGoogleMaps.createMap({
      boundingRect: boundingRect,
      cameraPosition: {
        target: {
          latitude: options.center.lat,
          longitude: options.center.lng,
        },
        zoom: options.zoom
      },
      preferences: {
        appearance: { type: this.getMapType(options.style) },
        controls: { isCompassButtonEnabled: false, isMyLocationButtonEnabled: false }
      }
    })
  }
  getMapType(style) {
    const mapTypes = ['', 'roadmap', 'satellite', '', 'hybrid']
    return _.indexOf(mapTypes, style)
  }
  getBoundingRect(id) {
    const boundingRect = document.getElementById(id).getBoundingClientRect()
    return {
      width: Math.round(boundingRect.width),
      height: Math.round(boundingRect.height),
      x: Math.round(boundingRect.x),
      y: Math.round(boundingRect.y),
    }
  }
}



import INIT from '@/init.js'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"

class Analytics {
  constructor() {
    this.isInitialized = false
  }
  async initialize(that) {
    // initialize for web
    if (that.isSite) {
      await FirebaseAnalytics.initializeFirebase(INIT.FIREBASE)
      this.isInitialized = true
    } else if (that.platform !== 'web') {
      this.isInitialized = true
    }
    if (! this.isInitialized) { return }
    // userId
    FirebaseAnalytics.setUserId({
      userId: that.storage.userId,
    })
    // theme
    this.setUserProperty({
      name: 'theme',
      value: that.theme
    })
    setTimeout(() => {
      // paid
      if (that.platform !== 'web') {
        this.setUserProperty({
          name: 'paid',
          value: that.isPurchased ? 'true' : 'false'
        })
      }
    }, 5000)
  }
  setUserProperties(properties) {
    if (! this.isInitialized) { return }
    _.forEach(properties, property => {
      this.setUserProperty(property)
    })
  }
  // セット
  setUserProperty(property) {
    if (! this.isInitialized) { return }
    FirebaseAnalytics.setUserProperty({
      name: property.name,
      value: property.value,
    })
  }
  setScreenName(obj) {
    if (! this.isInitialized) { return }
    FirebaseAnalytics.setScreenName({
      screenName: obj.screenName,
      nameOverride: obj.nameOverride,
    })  
  }
}

export default new Analytics()

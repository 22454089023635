<template>
  <fragment>
    <v-list-item
      :key="place.placeId"
      @click="select(place)"
    >
      <v-list-item-icon v-if="type === 'result'"><v-icon>{{ icon }}</v-icon></v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ place.name || place.primaryText }}</v-list-item-title>
        <v-list-item-subtitle>{{ replaceAddress(place.formattedAddress) || replaceAddress(place.secondaryText) }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="type === 'result'"><v-icon>north_west</v-icon></v-list-item-action>
    </v-list-item>
    <v-divider
      :key="'divider' + place.placeId"
      inset
    ></v-divider>
  </fragment>
</template>

<script>
  import Places from '@/commons/modules/places'

  export default {
    props: ['place', 'icon', 'type'],
    methods: {
      // 選択
      // 要変更 silent access true
      async select(place) {
        place = _.has(place, 'latlng') ? place : await Places.getPlaceById(place, true)
        if (_.has(place, 'latlng')) {
          this.panTo(place.latlng)
          this.addHistory(place)
          this.searchKeyword = place.name
          this.searchQuery = ''
          this.searchFocused = false
        }
      },
      // 地図移動
      panTo(latlng) {
        this.currentCenter = {lat: latlng[0], lng: latlng[1]}
      },
      // 履歴保存
      addHistory(newPlace) {
        const histories = _.filter(this.histories, place => { return place.placeId !== newPlace.placeId })
        histories.unshift(newPlace)
        this.histories = _.take(histories, 10)
      },
      // アドレス調整
      replaceAddress(address) {
        return address ? address.replace('日本、', '').replace(' 日本', '').replace('〒', '').replace(/[0-9]{3}-[0-9]{4}/, '') : ''
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>

import INIT from '@/init.js'
import { Capacitor } from '@capacitor/core'
import { initializeApp } from "firebase/app"
import { getFirestore, collection, doc, getDoc, getDocs, setDoc, query, serverTimestamp } from "firebase/firestore"
import { getAuth, signInAnonymously, initializeAuth, indexedDBLocalPersistence } from "firebase/auth"

INIT.FIREBASE.apiKey = INIT.API_KEY.gcp[Capacitor.getPlatform()]
const app = initializeApp(INIT.FIREBASE)
initializeAuth(app, {
  persistence: indexedDBLocalPersistence
})

class Firestore {
  constructor(firestore) {
    this.db = firestore
    this.isReady = (() => {
      // Authlization
      return new Promise((resolve, reject) => {
        const auth = getAuth()
        signInAnonymously(auth).then(() => {
          resolve(true)
        }).catch(() => {
          console.log('login error')
          reject(false)
        })
      })
    })()
  }

  // ユーザー情報の保存
  async setUser(storage) {
    const doc = await this.getDoc('users', storage.userId)
    const data = {
      updatedAt: serverTimestamp(),
      storage: storage,
      userId: storage.userId,
    }
    if (doc) {
      data.createdAt = serverTimestamp()
    }
    return await this.set('users', data, storage.userId)
  }

  // set (generated id collectionでは記述が違うよ)
  async set(name, data, id) {
    await this.isReady
    const targetDoc = id ? doc(this.db, name, id) : doc(collection(this.db, name))
    await setDoc(targetDoc, data, {merge: true})
    return targetDoc
  }

  // get single doc
  async getDoc(name, id) {
    await this.isReady
    const snap = await getDoc(doc(this.db, name, id))
    return snap.data()
  }

  // docs by query
  async getByQuery(name, where) {
    await this.isReady
    let docs = []
    if (where) {
      const querySnapshot = await getDocs(query(collection(this.db, name), where))
      querySnapshot.forEach(doc => {
        docs.push(_.assign(doc.data(), {id: doc.id}))
      })  
    }
    return docs
  }

  // all docs
  async getAll(name) {
    await this.isReady
    const docs = []
    const querySnapshot = await getDocs(collection(this.db, name))
    querySnapshot.forEach(doc => {
      docs.push(_.assign(doc.data(), {id: doc.id}))
    })
    return docs
  }

  // timestamp
  getServerTimestamp() {
    return serverTimestamp()
  }

}

export default new Firestore(getFirestore())

<template>
  <v-card elevation="0">

    <v-container v-show="! searchKeyword">
      <v-row no-gutters>
        <!-- 履歴 -->
        <v-col cols="6">
          <v-card-title><v-icon>schedule</v-icon>&nbsp;{{ $t('history') }}</v-card-title>
          <v-card-text v-if="! histories.length">{{ $t('noHistory') }}</v-card-text>
          <v-list>
            <ToolbarListItem
              v-for="place in histories"
              :key="place.placeId"
              :place="place"
              type="history"
            />
          </v-list>
          <v-btn
            text
            color="primary"
            @click="clearHistories"
            :disabled="! histories.length"
          >{{ $t('clearHistory')}}</v-btn>
        </v-col>

        <!-- トレンド -->
        <v-col cols="6">
          <v-card-title><v-icon>trending_up</v-icon>&nbsp;{{ $t('trend') }}</v-card-title>
          <v-list>
            <ToolbarListItem
              v-for="place in trends"
              :key="place.placeId"
              :place="place"
              type="trend"
            />
          </v-list>
        </v-col>
      </v-row>
    </v-container>

    <!-- 結果 -->
    <v-list v-if="places.length">
      <ToolbarListItem
        v-for="place in places"
        :key="place.placeId"
        :place="place"
        icon="place"
        type="result"
      />
    </v-list>
  </v-card>
</template>

<script>
  import Firestore from '@/commons/modules/firestore'
  import Places from '@/commons/modules/places'
  import ToolbarListItem from '@/components/UIsToolbarListItem.vue'

  export default {
    components: { ToolbarListItem },
    data() {
      return {
        places: [],
        trends: [],
      }
    },
    async mounted() {
      const res = await Firestore.getDoc('caches', 'trends_48').catch(err => { console.log(err) })
      if (res?.places) {
        this.trends = res.places
      }
    },
    watch: {
      async searchQuery() {
        if (this.searchKeyword && this.searchQuery) {
          const res = await Places.getAutocomplete(this.searchQuery)
          // 一度空にしないとエラーになる不具合対策
          this.places = []
          setTimeout(() => {
            this.places = res && res.length ? res : []
          }, 0)
        } else {
          this.places = []
        }
      }
    },
    methods: {
      // 履歴削除
      clearHistories() {
        this.histories = []
      },
    },
    i18n: {
      messages: {
        ja: {
          history: '履歴',
          noHistory: '履歴はありません',
          clearHistory: '履歴を削除する',
          trend: '検索トレンド',
        },
        en: {
          history: 'History',
          noHistory: 'No history',
          clearHistory: 'Clear histories',
          trend: 'Trend'
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-card {
    padding-top: calc(var(--safe-area-top) + 80px) !important;
    height: 100%;
  }
  .v-card__title {
    padding-bottom: 0 !important;
    font-size: 16px;
  }
  .v-card__text {
    text-align: center;
    margin-top: 30px;
  }
  .container {
    padding: 0 0 20px;
    height: calc(100% - 80px);
    overflow-y: scroll;
  }
</style>

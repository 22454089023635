<template>
  <v-container fill-height :style="{paddingBottom: footerHeight + 'px'}">
    <v-list>
      <v-list-item
        v-for="(tweet, index) in allTweets"
        :key="tweet.tweet.id"
      >
        <v-lazy
          v-model="tweet.isActive"
          width="100%"
          min-height="300px"
          transition="fade-transition"
          :options="{
            threshold: .01
          }"
        >
          <v-list-item-content>
            <Tweet
              :id="tweet.tweet.id"
              :options="{ theme: isThemeDark ? 'dark' : 'light', lang: 'ja' }"
            >
              <div class="loader" v-if="index === 0">
                <v-progress-circular
                  indeterminate
                ></v-progress-circular>
              </div>
            </Tweet>
            <div :class="['button', {dark: isThemeDark}]" v-show="showButton">
              <v-btn
                @click="panTo(tweet.share)"
              ><v-icon>map</v-icon> {{ $t('panTo') }}</v-btn>
            </div>
          </v-list-item-content>
        </v-lazy>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import OLD_TWEETS from '@/jsons/tweets.json'

  export default {
    components: { Tweet },
    data() {
      return {
        showButton: false,
        allTweets: [],
      }
    },
    computed: {},
    mounted() {
      this.allTweets = this.getAllTeets()
      this.showButton = false
      setTimeout(() => {
        this.showButton = true
      }, 5000)
    },
    watch: {
      tweets() {
        this.allTweets = this.getAllTeets()
      },
      isThemeDark() {
        this.allTweets = []
        setTimeout(() => {
          this.allTweets = this.getAllTeets()
        }, 1000)
      }
    },
    methods: {
      getAllTeets() {
        const tweets = this.tweets.length < 100 ? _.concat(this.tweets, OLD_TWEETS) : this.tweets
        return _.map(tweets, tweet => { return _.assign(tweet, {isActive: false}) })
      },
      panTo(share) {
        this.currentCenter = share.position
        this.currentZoom = share.zoom
        this.age = share.age
        this.tabId = 'tab0'
      },
    },
    i18n: {
      messages: {
        ja: {
          panTo: '地図で表示する',
        },
        en: {
          panTo: 'Open on map',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: calc(var(--safe-area-top) + 20px);
  }
  .v-list {
    padding: 0;
  }
  .v-list-item {
    padding: 0;
  }
  .v-list-item__content {
    padding: 0;
    min-height: 300px;
    justify-content: center;
    &>div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .loader {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button {
    text-align: center;
    position: relative;
    top: -20px;
    background-color: #FFF;
    border: 1px solid #CCC;
    border-top: none;
    border-radius: 0 0 10px 10px;
    padding: 8px 0 16px;
    z-index: 9999;
    max-width: 550px;
    &.dark {
      border: 1px solid #333;
      border-top: none;
      background-color: #000;
    }
  }
</style>

/* global google */
import INIT from '@/init.js'
import axios from 'axios'
import { Loader } from "@googlemaps/js-api-loader"
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"
import { _ } from 'core-js'


// mapGoogle.jsの設定と要同一
const loader = new Loader({
  apiKey: INIT.API_KEY.gcp.web,
  version: 'weekly',
  libraries: ['places'],
})
loader.load().then()

class Places {
  // AUTOCOMPLETE
  async getAutocomplete(keyword) {
    if (keyword) {
      let places = []
      const keywordTrimed = keyword.replace(/[\u3000]/g, ' ').replace('/ {2,}/g', '').trim() // 全角→半角/複数まとめ/前後削除
      if (keywordTrimed) {
        // database api
        const res = await axios.get('https://sirius.aroundit.net/places/autocomplete', {
          timeout: 3000,
          params: {keyword: keywordTrimed}
        }).catch(err => {console.log(err)})
        if (_.isArray(res?.data)) {
          console.log('complete from db')
          places = res?.data
        } else {
          // place api
          await new Promise(resolve => {
            const autocompleteService = new google.maps.places.AutocompleteService()
            autocompleteService.getPlacePredictions({
              input: keywordTrimed,
              componentRestrictions: {country: 'jp'},
            }, res => {
              // 結果が帰ってこない場合の検証
              console.log('complete from api')
              if (res) {
                _.forEach(res, place => {
                  places.push({
                    fullText: place.description,
                    primaryText: place.structured_formatting.main_text, 
                    secondaryText: place.structured_formatting.secondary_text,
                    placeId: place.place_id,
                  })
                })
                resolve()
              }
              axios.post('https://sirius.aroundit.net/places/autocomplete', {
                keyword: keywordTrimed,
                results: JSON.stringify(places),
              })
            })
          })
        }
      }
      return places
    }
  }

  // async searchPlace(keyword) {
  //   let place = null
  //   if (keyword) {
  //     const keywordTrimed = keyword.replace(/[\u3000]/g, ' ').replace('/ {2,}/g', '').trim() // 全角→半角/複数まとめ/前後削除
  //     if (keywordTrimed) {
  //       const resId = await this.getPlaceByQuery(keywordTrimed, false)
  //       console.log('id from api: ', resId)
  //       if (_.has(resId, 'data.candidates')) {
  //         const resDB = await axios.get('https://sirius.aroundit.net/places/place', {
  //           timeout: 3000,
  //           params: {
  //             placeId: resId.data.candidates[0].place_id,
  //             silentAccess: true,
  //           }
  //         })
  //         console.log('detail from db: ', resDB)
  //         if (_.has(resDB, 'data.latlng')) {
  //           place = resDB.data
  //         } else {
  //           const resAPI = await this.getPlaceByQuery(keywordTrimed, true)
  //           console.log('detail from api: ', resAPI)
  //           if (_.has(resAPI, 'data.candidates')) {
  //             place = {
  //               placeId: resAPI.data.candidates[0].place_id,
  //               name: resAPI.data.candidates[0].name,
  //               formattedAddress: resAPI.data.candidates[0].formatted_address,
  //               latlng: [resAPI.data.candidates[0].geometry.location.lat, resAPI.data.candidates[0].geometry.location.lng],
  //             }
  //             // axios.post('https://sirius.aroundit.net/places/place', place)
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return place
  // }

  // place by query
  // async getPlaceByQuery(keyword, detail) {
  //   return await axios.get('https://map70.aroundit.net/api/cors', {
  //     params: {
  //       url: 'https://maps.googleapis.com/maps/api/place/findplacefromtext/json',
  //       params: JSON.stringify({
  //         input: keyword,
  //         inputtype: 'textquery',
  //         language: 'ja',
  //         fields: detail ? 'place_id,name,formatted_address,geometry' : '',
  //         key: INIT.API_KEY.gcp.web,
  //       }),
  //     },
  //   })
  // }
  
  // place by id
  // place.placeId & place.primaryTextが必要
  async getPlaceById(place, silentAccess) {
    if (_.has(place, 'placeId')) {
      // database
      const resDB = await axios.get('https://sirius.aroundit.net/places/place', {
        timeout: 3000,
        params: {
          placeId: place.placeId,
          silentAccess: silentAccess || false,
        }
      })
      if (_.has(resDB, 'data.latlng')) {
        console.log('detail from db')
        place = resDB.data
      } else {
        // geocoding
        const resAPI = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
          params: {
            place_id: place.placeId,
            key: INIT.API_KEY.gcp.web
          }
        }).catch(err => {console.log(err)})
        if (_.has(resAPI, 'data.results')) {
          console.log('detail from geocode api')
          place = {
            placeId: place.placeId,
            name: place.primaryText,
            formattedAddress: resAPI.data.results[0].formatted_address,
            latlng: [resAPI.data.results[0].geometry.location.lat, resAPI.data.results[0].geometry.location.lng],
          }
          axios.post('https://sirius.aroundit.net/places/place', place)
        }
      }
    }
    FirebaseAnalytics.logEvent({
      name: 'search',
      params: {search_term: place.name},
    })
    return place
  }

  // place by latlng
  async getPlaceByLatlng(latlng) {
    let place = {}
    if (_.has(latlng, 'lat') && _.has(latlng, 'lng')) {
      // geocoding
      const res = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: latlng.lat + ',' + latlng.lng,
          key: INIT.API_KEY.gcp.web
        }
      }).catch(err => {console.log(err)})
      if (_.has(res, 'data.results')) {
        console.log('detail from geocode api')
        place = {
          placeId: res.data.results[0].place_id,
          name: '',
          formattedAddress: res.data.results[0].formatted_address,
          latlng: [res.data.results[0].geometry.location.lat, res.data.results[0].geometry.location.lng],
          addressComponents: res.data.results[0].address_components,
        }
        // axios.post('https://sirius.aroundit.net/places/autocomplete', place)
      }
    }
    return place
  }

}

export default new Places()

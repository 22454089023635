// Required cordova-plugin-android-notch
import safeAreaInsets from 'safe-area-insets'

class SafeArea {
  constructor() {
    this.android = {top: 0, bottom: 0, right: 0, left: 0}
    this.ios = {top: 0, bottom: 0, right: 0, left: 0}
    this.total = {top: 0, bottom: 0, right: 0, left: 0}
  }

  // get total safe area
  async get() {
    await this.setAndroid()
    this.setIos()
    _.forEach(['top', 'bottom', 'left', 'right'], direction => {
      this.total[direction] = this.ios[direction] + this.android[direction]
    })
    return _.cloneDeep(this.total)
  }

  // safe area for css
  setCss() {
    document.documentElement.style.setProperty('--safe-area-top', this.total.top + 'px')
    document.documentElement.style.setProperty('--safe-area-bottom', this.total.bottom + 'px')
    document.documentElement.style.setProperty('--safe-area-left', this.total.left + 'px')
    document.documentElement.style.setProperty('--safe-area-right', this.total.right + 'px')
  }

  // safe area for android
  async setAndroid() {
    if (window.AndroidNotch) {
      return new Promise(resolve => {
        window.AndroidNotch.getInsetTop(cutout => {
          this.android.top = cutout || 20
          window.AndroidNotch.getInsetBottom(cutout => {
            this.android.bottom = cutout
            window.AndroidNotch.getInsetLeft(cutout => {
              this.android.left = cutout
              window.AndroidNotch.getInsetRight(cutout => {
                this.android.right = cutout
                resolve()
              })
            })
          })
        })  
      })
    } else {
      return
    }
  }

  // safe area for ios
  setIos() {
    this.ios = safeAreaInsets
  }
}

export default new SafeArea()

<template>
  <!-- loadingが瞬時に終わるとエラーになる（awaitと共に使って！） -->
  <v-dialog
    v-model="isLoadings"
    persistent
    overlay-opacity="0.7"
  >
    <div class="text-center">
      <v-progress-circular
        v-if="isLoadings"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {}
    },
    computed: {},
    async mounted() {},
    methods: {},
  }
</script>

<style lang="scss" scoped>
  ::v-deep .v-dialog {
    overflow: hidden;
    box-shadow: none;
  }
</style>

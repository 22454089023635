// common mixin
import INIT from '@/init'
import { Capacitor } from '@capacitor/core'
// import vuetify from '@/commons/modules/vuetify'
import UAParser from 'ua-parser-js'

export default {
  data: {
    INIT: INIT,
  },
  computed: {
    // ライブラリ
    _() { return _ },
    // platform: web, ios, android
    platform() { return Capacitor.getPlatform() },
    // user agent for web
    userAgent() {
      const ua = new UAParser
      const os = _.get(ua.getOS(), 'name')
      return os === 'Android' ? 'android' : os === 'iOS' ? 'ios' : 'others'
    },
    // App
    isApp() { return Capacitor.getPlatform() !== 'web' },
    // Site
    isSite() { return _.includes(INIT.URLS.site, location.hostname) },
    // Development Mode
    isDev() {
      return (location.protocol === 'http:' && this.platform === 'web') ? true : false
    },
    // 大きなスクリーン
    isLargeScreen() { return this.bodyWidth >= 1264 },
    // 横配置
    isLandscape() { return this.bodyWidth > this.bodyHeight },
    // 言語
    lang() {
      if (Capacitor.getPlatform() === 'web') {
        // Site
        if (INIT.ROOT_LANGUAGE === 'ja') {
          return (_.includes(location.pathname, '/en/')) ? 'en' : 'ja'
        } else {
          return (_.includes(location.pathname, '/ja/')) ? 'ja' : 'en'
        }
      } else {
        // WevView
        return (navigator.language.match(/ja/)) ? 'ja' : 'en'
      }
    },
    // 日本語判定
    isJa() { return (this.lang === 'ja') },
    // ダークテーマ判定
    isThemeDark() {
      const isDark = (
        this.theme === 'system' && window.matchMedia('(prefers-color-scheme: dark)').matches
        || this.theme === 'dark'
      )
      return isDark
    },
    // URLパラメーター
    urlParam() {
      return this.$route.params.id || null
    },
  },

  methods: {
    // sleep
    sleep(time) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve()
        }, time)
      })
    },
  },
}

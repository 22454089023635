<template>
  <v-container>
    <v-card
      v-if="selectedPlace && ! favorite"
      :style="{paddingBottom: footerHeight + 'px'}"
    >
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="selectedPlace = null"
        ><v-icon large>expand_more</v-icon></v-btn>
        <v-spacer />
      </v-card-actions>
      <div class="d-flex flex-row justify-start flex-wrap">
        <v-img>
          <img v-if="selectedPlace.dataURL" :src="selectedPlace.dataURL">
          <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-img>
        <div>
          <template v-if="selectedPlace.address">
            <v-card-text>{{ selectedPlace.name }}</v-card-text>
            <v-card-text>{{ selectedPlace.address }}</v-card-text>
          </template>
          <template v-else>
            <v-card-text>{{ _.round(selectedPlace.position.lat, 5) }}, {{ _.round(selectedPlace.position.lng, 5) }}</v-card-text>
          </template>
        </div>
      </div>
      <v-card-text class="buttons">
        <v-row
          justify="space-between"
          no-gutters
        >
          <v-btn
            :disabled="selectedPlace.dataURL ? false : true"
            depressed rounded
            color="primary"
            @click="share"
          ><v-icon left>share</v-icon>{{ $t('share') }}</v-btn>
          <v-btn
            :disabled="selectedPlace.dataURL ? false : true"
            outlined rounded
            color="primary"
            @click="showDetail"
          ><v-icon left>bookmark</v-icon>{{ selectedPlace.isSaved ? $t('detail') : $t('save') }}</v-btn>
          <!-- <v-btn
            outlined rounded
            disabled
          ><v-icon left>add_circle_outline</v-icon>{{ $t('post') }}</v-btn> -->
          <v-btn
            outlined rounded
            color="primary"
            @click="openGmap"
          ><v-icon left>open_in_new</v-icon>{{ $t('gmap') }}</v-btn>
          <v-btn
            :disabled="selectedPlace.dataURL ? false : true"
            outlined rounded
            color="primary"
            @click="download"
          ><v-icon left>download</v-icon>{{ $t('download') }}</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"
  export default {
    data() {
      return {}
    },
    computed: {},
    watch: {
      selectedPlace() {
        if (this.selectedPlace) {
          // サムネイル追加
          if (! this.selectedPlace.dataURL) {
            setTimeout(() => {
              this.capturePlace = this.selectedPlace
            }, 3000) // もし改善しなかったらもう少し長く（要検討）
          }
        } else {
          this.capturePlace = null
        }
      },
      // サムネイル追加
      captureDataURL() {
        if (this.captureDataURL) {
          if (! this.favorite && this.selectedPlace) {
            // 取得中にselectedPlaceがなくなっている可能性
            this.selectedPlace = _.assign(_.cloneDeep(this.selectedPlace), {dataURL: this.captureDataURL})
            this.captureDataURL = null
            this.capturePlace = null
          }
        }
      },
    },
    methods: {
      // 詳細or保存
      showDetail() {
        this.favorite = _.cloneDeep(this.selectedPlace)
      },
      // シェア
      async share() {
        this.isLoadings = true
        if (! this.selectedPlace.placeId) {
          const detail = await this.selectedPlace.getDetail()
          this.selectedPlace = _.assign(_.cloneDeep(this.selectedPlace), detail)
        }
        await this.selectedPlace.share(this.$t('appName'), this.storage.userId)
        this.isLoadings = false
        // analytics
        FirebaseAnalytics.logEvent({
          name: 'share',
          params: {content_type: 'url', item_id: this.selectedPlace.id},
        })
      },
      // ダウンロード
      async download() {
        this.selectedPlace.download(this.platform)
      },
      // マップ
      openGmap() {
        window.open(this.selectedPlace.mapURL, '_system')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    padding: 0;
    position: relative;
  }
  .v-card {
    width: 100%;
    max-width: 540px;
    margin: 0 auto;
  }
  .v-card__actions {
    padding: 0;
  }
  .v-image {
    margin: 0 0 0 16px;
    width: 200px;
    flex: none;
    ::v-deep .v-responsive__content {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 110px;
    }
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
  .v-card__text {
    padding: 8px 16px 0;
  }
  .buttons {
    padding: 16px;
    .row {
      flex-wrap: nowrap;
      overflow: auto;
      justify-content: flex-start !important;
    }
    .v-btn {
      margin-right: 8px;
    }
  }
</style>

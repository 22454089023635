<template>
  <fragment>
    <div id="maps" :class="this.split">
      <div id="map-box1" :style="styleBox1">
        <Map1 />
        <div v-show="currentVisible && crosshair && ! isSite" class="crosshair"></div>
        <transition name="fade">
          <v-btn v-show="currentVisible && showLabel" class="label past">
            <span v-if="isJa">{{ age }} 年代</span>
            <span v-else>In {{ age }}</span>
          </v-btn>
        </transition>
      </div>
      <div id="map-box2" :style="styleBox2">
        <Map2 v-if="platform === 'web'" />
        <Map3 v-else />
        <div v-show="pastVisible && crosshair && ! isSite" class="crosshair"></div>
        <transition name="fade">
          <v-btn v-show="pastVisible && showLabel" class="label current">{{ $t('current') }}</v-btn>
        </transition>
      </div>
      <FabTarget />
    </div>
    <MapCanvas />
  </fragment>
</template>

<script>
  import Map1 from '@/components/Map1.vue'
  import Map2 from '@/components/Map2.vue'
  import Map3 from '@/components/Map3.vue'
  import FabTarget from '@/components/UIsFabTarget.vue'
  import MapCanvas from '@/components/MapCanvas.vue'

  export default {
    components: { Map1, Map2, Map3, FabTarget, MapCanvas },
    data() {
      return {
        labelTimeout: null,
        showLabel: false,
      }
    },
    computed: {
      currentVisible() { return this.targetPosition.x > 20 && this.targetPosition.y > 20 },
      pastVisible() { return this.targetPosition.x < 80 && this.targetPosition.y < 80 },
      styleBox1() {
        if (this.isVertical) {
          return {width: this.targetPosition.x + '%', height: 100 + '%'}
        } else {
          return {width: 100 + '%', height: this.targetPosition.y + '%'}
        }
      },
      styleBox2() {
        if (this.isVertical) {
          return {width: 100 - this.targetPosition.x + '%', height: 100 + '%'}
        } else {
          return {width: 100 + '%', height: 100 - this.targetPosition.y + '%'}
        }
      },
      _renewLabel() { return this.age + this.targetPosition.x + this.targetPosition.y }
    },
    created() {
      // 初期位置
      this.currentCenter = _.clone(this.center)
      this.currentZoom = this.zoom
    },
    watch: {
      // storage読み込み後に初期位置再修正
      isStorageReady() {
        if (this.isStorageReady) {
          this.currentCenter = _.clone(this.center)
          this.currentZoom = this.zoom
        }
      },
      _renewLabel() {
        this.showLabel = true
        clearTimeout(this.labelTimeout)
        this.labelTimeout = setTimeout(() => {
          this.showLabel = false
        }, 5000)
      },
      // 保存データ更新
      currentCenter() {
        this.center = _.clone(this.currentCenter)
      },
      currentZoom() {
        this.zoom = this.currentZoom
      },
    },
  }
</script>

<style lang="scss" scoped>
  #maps {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    display: flex;
    overflow: hidden;
    &.vertical {
      flex-direction: row;
    }
    &.horizontal {
      flex-direction: column;
    }
  }
  #map-box1 {
    height: 100%;
    width: 50%;
    position: relative;
  }
  #map-box2 {
    height: 100%;
    width: 50%;
    position: relative;
  }
  .crosshair {
    pointer-events: none;
  }
  .crosshair:before,
  .crosshair:after {
    content: '';
    width: 30px;
    display: inline-block;
    border-top: .9px solid #fff;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    box-shadow: 0 .9px 0 #000;
  }
  .crosshair:after {
    transform: translate(-50%, -50%) rotate(90deg);
    box-shadow: 0 -0.9px 0 #000;
  }
  .label {
    width: 100px;
    position: absolute;
    top: 140px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }
  .horizontal {
    .label {
      top: 50%;
      left: 60px;
    }
  }
</style>

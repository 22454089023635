<template>
  <fragment>
    <div id="map4"></div>
  </fragment>
</template>

<script>
  import L from '@/modules/leaflet.js'
  import Place from '@/modules/place.js'

  export default {
    data() {
      return {
        mapId: 'map4',
        map: null,
        locationMarker: null,
      }
    },
    computed: {
      isPrimary() { return this.primaryMap === this.mapId },
    },
    mounted() {
      setTimeout(() => {
        this.map = L.draw({
          id: this.mapId,
          center: this.currentCenter,
          zoom: this.currentZoom,
          tileLayer: false,
        })
        this.addEvent()
        this.observeResize()
      }, 0)
    },
    watch: {
      // 地図移動
      currentCenter() {
        if (! this.isPrimary) {
          this.map.setView(this.currentCenter, this.currentZoom, {animate: false})
        }
      },
      // ズーム変更
      currentZoom() {
        if (! this.isPrimary) {
          this.map.setZoom(this.currentZoom)
        }
      },
      // 地図分割方向
      split() {
        this.map.refresh()
      },
      currentLocation() {
        if (this.currentLocation.timestamp > this.$dayjs().valueOf() - (60 * 1000)) {
          if (! this.locationMarker) {
            // 新規
            const iconUrl = this.currentLocation.heading === 0 || this.currentLocation.heading === 360 ? require('@/assets/location-marker.svg') : require('@/assets/location-marker-heading.svg')
            this.currentCenter = this.currentLocation.position
            this.locationMarker = this.map.addMarker(this.currentLocation.position, {
              iconUrl: iconUrl,
              iconSize: [60, 60],
              iconAnchor: [30, 30],
              popupAnchor: [0, 0],
            }, this.currentLocation.heading)
          } else {
            // 更新
            const iconUrl = this.currentLocation.heading === 0 || this.currentLocation.heading === 360 ? require('@/assets/location-marker.svg') : require('@/assets/location-marker-heading.svg')
            this.locationMarker.setLatLng(this.currentLocation.position)
            this.locationMarker.setRotationAngle(this.currentLocation.heading)
            this.locationMarker.setIcon(L.icon({
              iconUrl: iconUrl,
              iconSize: [60, 60],
              iconAnchor: [30, 30],
              popupAnchor: [0, 0],
            }))
          }
        } else {
          this.map.removeMarker(this.locationMarker)
          this.locationMarker = null
        }
      }
    },
    methods: {
      observeResize() {
        new ResizeObserver(() => {
          if (document.getElementById(this.mapId)) { // 別ページ表示時のエラー対策
            clearTimeout(this.resizeTimeout)
            this.resizeTimeout = setTimeout(() => {
              this.map.refresh()
            }, 500)
          }
        }).observe(document.getElementById(this.mapId))
      },
      addEvent() {
        // drag
        this.map.on('drag zoom', () => {
          this.primaryMap = this.mapId
          this.currentCenter = this.map.getCenter()
          this.currentZoom = _.round(this.map.getZoom())
        })
        this.map.on('dragend', () => {
          this.primaryMap = null
        })
        if (! this.isSite) {
          // click
          this.map.onClick(() => {
            if (this.selectedPlace) {
              this.selectedPlace = null
            } else {
              this.showUI = ! this.showUI
            }
          })
          // long press
          this.map.onContextmenu(async e => {
            this.showUI = true
            this.selectedPlace = await Place.init({
              position: e.latlng,
              zoom: this.currentZoom,
              age: this.age,
            }, this.style)
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  #map4 {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute !important;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.01); // for ios
  }
</style>

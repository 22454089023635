<template>
  <v-dialog
    v-model="searchFocused"
    fullscreen
    attach="#map-uis"
    content-class="toolbar-dialog"
    persistent
    no-click-animation
    :retain-focus="false"
  >
    <ToolbarList />
  </v-dialog>
</template>

<script>
  import ToolbarList from '@/components/UIsToolbarList.vue'

  export default {
    components: { ToolbarList },
    watch: {
      searchFocused() {
        const dialog = document.querySelector('.toolbar-dialog')
        if (dialog) {
          if (this.searchFocused) {
            dialog.parentElement.style.display = 'flex'
          } else {
            dialog.parentElement.style.display = 'none'
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>

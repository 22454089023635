import { nanoid } from 'nanoid'

const VER = 4.1

export default Object.freeze({
	APP_ID: 'map70',
	VER: VER,
	LOCAL_DATA_STRUCTURE: {
		ver: VER,
		activated: new Date().getTime(),
		userId: nanoid(10),
		isAdmin: false,
    launches: [new Date().getTime()], // ver4.1
		data: {
      theme: 'system', // 'system', 'dark', 'light'
      playedTutorial: 0, // ver4.0
      isReviewed: false, // ver4.1
      center: {lat: 35.7052579, lng: 139.752536},
      zoom: 15,
			split: 'vertical', // vertical, horizontal (ver2.0で追加)
			style: 'hybrid', // ver2.0
      histories: [], // ver3.0
      favorites: [], // ver3.0 id, position, address, name, desc, age(ver4.0)
      crosshair: true, // ver4.0
    },
	},
  STORE_STATE: {
    showUI: true,
    age: 76,
    primaryMap: '', // タッチ開始マップ
    currentCenter: {lat: 0, lng: 0},
    currentZoom: 0,
    currentLocation: {position: {lat: 0, lng: 0}, heading: 0, timestamp: 0},
    searchFocused: false,
    searchKeyword: '', // 検索語
    searchQuery: '', // 検索語（for places api）
    selectedPlace: null, // マーカー選択地点
    capturePlace: null, // キャプチャ用地点
    captureDataURL: null,
    favorite: null, // 編集画面用
    targetPosition: {x: 50, y: 50}, // 中央ターゲット
    showFavoriteMarkers: true, // お気に入りマーカー表示・非表示
    nativeMapRefreshed: 0,
    tweets: [],
  },
  ROOT_LANGUAGE: 'ja', // ja or en
	FORM_ID: 115,
	URLS: {
    site: 'https://map70.aroundit.net',
		play: 'https://play.google.com/store/apps/details?id=net.aroundit.map70',
		app: 'https://apps.apple.com/jp/app/id1177752029',
    terms: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/935',
    privacy: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/557',
    request: 'https://apps.wanakijiji.com/wp-json/wp/v2/pages/115',
	},
	API_KEY: {
		admob: {
      banner: {
        android: 'ca-app-pub-1693924705262886/9878216462',
        ios: 'ca-app-pub-1693924705262886/3831682868',
      },
      interstitial: {
        android: 'ca-app-pub-1693924705262886/5824993128',
        ios: 'ca-app-pub-1693924705262886/6572831374',
      },
      reward: {
        android: 'ca-app-pub-1693924705262886/6431664874',
        ios: '',
      },
		},
    gcp: {
      web: 'AIzaSyAGEyoxnUUPX-URP34-X_xio7MQNGSsnzY',
      android: 'AIzaSyAGEyoxnUUPX-URP34-X_xio7MQNGSsnzY',
      ios: 'AIzaSyAGEyoxnUUPX-URP34-X_xio7MQNGSsnzY',
    },
    mapbox: 'pk.eyJ1Ijoic2hpZ2d5IiwiYSI6ImNqbHV3cGcxdjBueGozcW9kd2x2cXBocmYifQ.0PpPputD2ZZkh77kk3CSig'
	},
  FIREBASE: {
    apiKey: "AIzaSyBIhbi-hrKLzQhpujeRuH3Yn1otC5hEIEU",
    authDomain: "map70-201610.firebaseapp.com",
    databaseURL: "https://map70-201610.firebaseio.com",
    projectId: "map70-201610",
    storageBucket: "map70-201610.appspot.com",
    messagingSenderId: "229138098640",
    appId: "1:229138098640:web:f3c0155d8fb8c3502db1d5",
    measurementId: "G-FBWD2DXCE5",
  },
  SOURCE: {
    name: {
      ja: '国土地理院',
      en: 'GSI',
    },
    url: {
      ja: 'http://maps.gsi.go.jp/development/ichiran.html',
      en: 'http://maps.gsi.go.jp/development/ichiran.html',
    },
  },
  // 表示メニュー一覧
  MENU: {
    order: ['home', 'purchase', 'request', 'contact', 'review', 'terms', 'privacy', 'admin'], // 'home', 'request', 'contact', 'review', 'language'
    extras: [], // item objectを入れて
    pcOpen: false
  },
  COLOR: {
    light: {
      primary: '#ff9800',
      secondary: '#4caf50',
      accent: '#1976D2',
      error: '#f44336',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#f5f5f5',
      background: '#F5F5F5',
    },
    dark: {
      primary: '#FFA726', // -1
      secondary: '#66BB6A', // -1
      accent: '#1976D2',
      error: '#f44336',
      warning: '#f44336',
      info: '#607d8b',
      success: '#8bc34a',
      statusBar: '#1E1E1E',
      background: '#121212',
    }
  },
	TICKETS: {
    ios: [{
      id: 'net.aroundit.map70.monthly',
      name: {ja: '１ヶ月間', en: 'For One Month'},
      period: {ja: '月', en: 'Month'},
      active: true,
    }, {
      id: 'net.aroundit.map70.yearly',
      name: {ja: '１年間', en: 'For One Year'},
      period: {ja: '年', en: 'Year'},
      active: false,
    }],
    android: [{
      id: 'monthly',
      name: {ja: '１ヶ月間', en: 'For One Month'},
      period: {ja: '月', en: 'Month'},
      active: true,
    }, {
      id: 'yearly',
      name: {ja: '１年間', en: 'For One Year'},
      period: {ja: '年', en: 'Year'},
      active: false, // 非表示 ver 4.08
    }, {
      id: 'yearly250',
      name: {ja: '１年間', en: 'For One Year'},
      period: {ja: '年', en: 'Year'},
      active: false, // 非表示
    }],
  },
})

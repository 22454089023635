<template>
  <fragment>

    <TheAppbar :title="$t('title')" />

    <v-main :style="{minHeight: this.bodyHeight + 'px'}">
      <v-container class="text">
        <p v-html="$t('desc')"></p>
        <TheForm page-type="contact" />
      </v-container>
    </v-main>

  </fragment>
</template>

<script>
  import TheAppbar from '@/commons/components/TheAppbar.vue'
  import TheForm from '@/commons/components/TheForm.vue'

  export default {
    data() {
      return {}
    },
    i18n: {
      messages: {
        ja: {
          title: 'お問い合わせ',
          desc: 'ご利用のアプリに関してご不明な点やお気づきの点などがございましたら、以下のメールフォームよりお問い合わせ下さい。',
        },
        en: {
          title: 'Contact Us',
          desc: 'Please send us your inquiry.',
        }
      }
    },
    components: { TheForm, TheAppbar },
  }
</script>

<style scoped>
</style>

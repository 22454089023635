<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>新機能の紹介</v-card-title>
      <div class="d-flex flex-column justify-space-between align-center">
        <v-img
          src="@/assets/tutorial01.svg"
          width="150px"
          height="150px"
        ></v-img>
      </div>
      <v-card-subtitle><strong>「画面長押し」</strong><br>お試しください！</v-card-subtitle>
      <v-card-text>以下の機能に対応します。</v-card-text>
      <v-card-text class="list">
        <v-btn rounded small><v-icon>share</v-icon>シェア</v-btn>
        <v-btn rounded small><v-icon>bookmark</v-icon>お気に入り登録</v-btn>
        <v-btn rounded small><v-icon>open_in_new</v-icon>マップアプリで開く</v-btn>
        <v-btn rounded small><v-icon>download</v-icon>キャプチャ画像保存</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    data() {
      return {
        // 新しいチュートリアルを作成したらバージョンを上げる
        tutorialVer: 4.1,
        dialog: false,
      }
    },
    computed: {},
    mounted() {
    },
    watch: {
      // Storage復元後表示判定
      isStorageReady() {
        if (this.isStorageReady) {
          if (this.playedTutorial < this.tutorialVer && ! this.isSite) {
            this.dialog = true
            this.playedTutorial = this.tutorialVer
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  // 広告にかぶってしまう対策
  .v-dialog__content {
    ::v-deep .v-dialog {
      margin-bottom: 100px;
    }
  }
  .v-card__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 1.4;
    padding: 8px 24px !important;
    strong {
      font-size: 1.6em;
      color: var(--v-primary-base);
    }
  }
  .v-card__text {
    padding: 0 24px !important;
  }
  .v-image {
    margin: 8px 0;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 6px 0 0;
  }
  .v-btn {
    margin: 3px;
  }
  .v-icon {
    margin-right: 8px;
  }
</style>

<template>
  <v-container fill-height :style="{paddingBottom: footerHeight + 'px'}">
    <v-select
      v-model="selectedTag"
      :items="tagOptions"
      filled
      :label="$t('filter')"
    ></v-select>
    <v-card
      v-for="(favorite, index) in favoritesFull"
      :key="index"
    >
      <v-btn
        fab
        absolute
        v-if="favorite.age"
        class="age"
      >{{ favorite.age }}</v-btn>
      <v-img :src="getSrc(favorite)"></v-img>
      <v-card-title>{{ favorite.name }}</v-card-title>
      <v-card-text>{{ favorite.address }}</v-card-text>
      <v-card-text>{{ favorite.desc }}</v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          @click="panTo(favorite)"
        ><v-icon>map</v-icon>{{ $t('displayMap') }}</v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="showDetail(favorite)"
        ><v-icon>bookmark</v-icon>{{ $t('detail') }}</v-btn>
      </v-card-actions>
    </v-card>
    <div
      class="desc text-center"
      v-if="! favorites.length"
    >
      <p v-html="$t('noFavorites')"></p>
      <div class="d-flex flex-column justify-space-between align-center">
        <v-img
          src="@/assets/tutorial01.svg"
          width="150px"
          height="150px"
        ></v-img>
      </div>
      <v-card-text v-html="$t('explainHTML')"></v-card-text>
    </div>
  </v-container>
</template>

<script>
  import Place from '@/modules/place.js'

  export default {
    data() {
      return {
        selectedTag: this.$t('selectAll'),
        favoritesFull: [],
      }
    },
    computed: {
      filteredFavorites() {
        if (this.selectedTag === this.$t('selectAll')) {
          return this.favorites
        } else {
          return _.filter(this.favorites, favorite => {
            return _.includes(favorite.tags, this.selectedTag)
          })
        }
      },
      tagOptions() { return _.concat([this.$t('selectAll')], _.uniq(_.flatten(_.map(this.favorites, 'tags')))) }
    },
    watch: {
      filteredFavorites: {
        immediate: true,
        async handler() {
          this.favoritesFull = []
          for(const favorite of this.filteredFavorites) {
            const newFavorite = await Place.init(favorite)
            this.favoritesFull.push(newFavorite)
          }
        }
      }
    },
    methods: {
      showDetail(favorite) {
        this.favorite = favorite
      },
      panTo(favorite) {
        this.currentCenter = _.cloneDeep(favorite.position)
        this.age = favorite.age || 76 // 旧データにはない
        this.tabId = 'tab0'
      },
      getSrc(favorite) {
        if (favorite.dataURL) {
          return favorite.dataURL
        } else {
          return require('@/assets/thumbnail.png')
        }
      }
    },
    i18n: {
      messages: {
        ja: {
          filter: 'タグで絞り込む',
          selectAll: '全てを表示',
          displayMap: '地図で表示',
          detail: '詳細',
          noFavorites: 'お気に入りはまだありません。',
          explainHTML: '地図上で<strong>「画面長押し」</strong>をすると<br>お気に入り登録できます',
        },
        en: {
          filter: 'Filter',
          selectAll: 'Show all',
          displayMap: 'Display on Map',
          detail: 'Detail',
          noFavorites: 'There are no saved favorites yet.',
          explainHTML: 'You can register it as a favorite by <strong>Long Pressing</strong> the screen on the map.',
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  .container {
    padding-top: calc(var(--safe-area-top) + 20px);
  }
  .v-card {
    margin-bottom: 24px;
  }
  .v-card__text {
    padding-top: 0;
  }
  .v-card__actions {
    .v-icon {
      margin-right: 6px;
    }
  }
  .age {
    top: -8px;
    left: -8px;
    ::v-deep span {
      font-size: 1.2em;
    }
  }
  .desc {
    margin-top: 100px;
    .v-card__text {
      margin: 24px 0;
      ::v-deep strong {
        font-size: 1.6em;
        color: var(--v-primary-base);
      }
    }
  }
</style>

import { Capacitor } from '@capacitor/core'

export default {

  map: null,
  tileLayer: null,
  clickTimeout: null,
  isClickIgnore: false,

  // click
  // iosでlongpress&dbclick時にも発火する不具合対策
  onClick(callback) {
    if (! this.map) { return }
    this.map.on('click', () => {
      if (! this.isClickIgnore) {
        clearTimeout(this.clickTimeout)
        this.clickTimeout = setTimeout(() => {
          callback()
        }, 300)
      } else {
        this.isClickIgnore = false
      }
    })
    this.map.on('dblclick', () => {
      clearTimeout(this.clickTimeout)
      setTimeout(() => {
        clearTimeout(this.clickTimeout)
      }, 100)
    })
  },

  // long press
  // iosでclick時にも発火する不具合対策
  onContextmenu(callback) {
    this.map.on('contextmenu', e => {
      this.isClickIgnore = (Capacitor.getPlatform() === 'ios') ? true : false
      callback(e)
    })
  },

  // 再描画
  refresh() {
    if (! this.map) { return }
    this.map.invalidateSize()
  },

  // レイヤー（マーカー）一括削除
  removeLayers(layers) {
    if (! this.map) { return }
    _.forEach(layers, layer => {
      this.map.removeLayer(layer)
    })
  },


  // マーカー追加
  // addMarker(position, icon, angle) {
  //   if (! this.map) { return }
  //   return L.rotatedMarker(position, {
  //     icon: L.icon(icon),
  //     rotationAngle: angle,
  //     rotationOrigin: 'center center'
  //   }).addTo(this.map)
  // }

  // // マーカー一括削除
  // removeMarkers(markers) {
  //   _.forEach(markers, marker => {
  //     this.removeMarker(marker)
  //   })
  // }

  // // マーカー削除
  // removeMarker(marker) {
  //   if (! this.map) { return }
  //   if (marker) {
  //     this.map.removeLayer(marker)
  //   }
  // }

  // on(event, callback) { this.map.on(event, callback) },
  // getZoom() { if (! this.map) { return } else { return this.map.getZoom() } },
  // getCenter() { if (! this.map) { return } else { return this.map.getCenter() } },
  // panTo(options) { if (! this.map) { return } else { this.map.panTo(options) } },
  // setZoom(zoom) { if (! this.map) { return } else { this.map.setZoom(zoom) } },
  // setView(position, zoom, options) {
  //   if (! this.map) { return }
  //   else {
  //     zoom = zoom || this.map.getZoom()
  //     options = options || {}
  //     this.map.setView(position, zoom, options)
  //   }
  // },

  // addLayer(layer) { this.map.addLayer(layer) }
  // fitBounds(coordsArray) { this.map.fitBounds(coordsArray) }

}

<template>
  <v-footer
    v-if="footer"
    app
    padless
    :style="style"
    id="footer"
    :height="height"
  >
    <Ads />
    <v-tabs
      v-show="tabItems"
      v-model="tabId"
      background-color="transparent"
      grow
      hide-slider
      :icons-and-text="bodyWidth < 576"
    >
      <v-tab
        v-for="(tabItem, index) in tabItems"
        :key="index"
        :href="'#tab' + index"
      >
        <template v-if="bodyWidth < 576">
          {{ tabItem.title[lang] }}
          <v-icon>{{ tabItem.icon }}</v-icon>
        </template>
        <template v-else>
          <v-icon>{{ tabItem.icon }}</v-icon>
          {{ tabItem.title[lang] }}
        </template>
      </v-tab>
    </v-tabs>
  </v-footer>
</template>

<script>
  import { ResizeObserver } from 'resize-observer'
  import Ads from '@/commons/components/Ads.vue'
  export default {
    components: { Ads },
    props: ['tabItems'],
    data() {
      return {}
    },
    computed: {
      style() {
        return {
          left: (this.INIT.MENU.pcOpen && this.isLargeScreen) ? '280px' : 0,
          paddingBottom: this.safeArea.bottom + 'px',
        }
      },
      height() {
        return this.bannerSize.height + this.tabHeight + this.safeArea.bottom
      },
    },
    mounted() {
      new ResizeObserver(() => {
        if (document.querySelector('.v-tabs')) {
          this.tabHeight = document.querySelector('.v-tabs').clientHeight
        }
      }).observe(document.querySelector('.v-tabs'))
      new ResizeObserver(() => {
        if (document.getElementById('footer')) {
          this.footerHeight = document.getElementById('footer').clientHeight
        }
      }).observe(document.getElementById('footer'))
    },
    watch: {},
  }
</script>

<style lang="scss" scoped>
  // スマホ縦
  .v-footer {
    ::v-deep .v-tabs-bar {
      height: 64px;
    }
    .v-tab {
      font-size: .8em;
      min-width: auto;
      padding: 0;
    }
    .v-icon {
      margin: 0 0 2px !important;
    }
  }
  // スマホ横
  .v-footer.low-height {
    ::v-deep .v-tabs-bar {
      height: 50px;
    }
  }
  // スマホ横＆タブレット
  .v-footer.wide-width {
    .v-tab {
      font-size: 1em;
    }
    .v-icon {
      margin: 0 6px 0 0 !important;
    }
  }
  .v-tabs {
    ::v-deep .v-slide-group__prev {
      display: none !important;
    }
  }
</style>

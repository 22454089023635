/* global */

import INIT from '@/init.js'
import { Loader } from "@googlemaps/js-api-loader"

// places.jsの設定と要同一
const loader = new Loader({
  apiKey: INIT.API_KEY.gcp.web,
  version: 'weekly',
  libraries: ['places']
})

export default class GoogleMaps {
  constructor() {
    this.map = null
    this.isReady = false
    this.google = null
  }

  // 地図描画
  async draw(options) {
    options = Object.assign({
      id: 'map',
      zoom: 15,
      center: {lat: 35.7052579, lng: 139.752536},
      tilt: 0,
    }, options)
    return new Promise(resolve => {
      loader.load().then(google => {
        this.google = google
        this.map = new google.maps.Map(document.getElementById(options.id), options)
        this.isReady = true
        resolve()
      })
    })
  }

  // マップタイプ変更
  setMapType(mapType) {
    if (! this.map) { return }
    this.map.setMapTypeId(mapType)
  }

  // 取得
  getCenter() { if (! this.map) { return } else { return {lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng()} } }
  getZoom() { if (! this.map) { return } else { return this.map.getZoom() } }

  // 設定
  panTo(latlng) { if (! this.map) { return } else { this.map.setCenter(latlng) } }
  setZoom(zoom) { if (! this.map) { return } else { this.map.setZoom(zoom) } }

  // refresh
  refresh() {
    if (! this.map) { return }
    this.google.maps.event.trigger(this.map, 'resize')
  }

}

<template>
  <v-app id="app" :style="style">
    <AppInitialize />
    <AppDrawer />
    <router-view />
    <AppNetwork />
    <AppLoading />
    <AppReviewDialog />
    <AdController />
    <TutorialDialog />
  </v-app>
</template>

<script>
  import INIT from '@/init.js'
  import AppInitialize from '@/commons/components/AppInitialize.vue'
  import AppDrawer from '@/commons/components/AppDrawer.vue'
  import AppNetwork from '@/commons/components/AppNetwork.vue'
  import AppLoading from '@/commons/components/AppLoading.vue'
  import AppReviewDialog from '@/commons/components/AppReviewDialog.vue'
  import AdController from '@/components/AdController.vue'
  import TutorialDialog from '@/components/TutorialDialog.vue'

  export default {
    components: { AppInitialize, AppDrawer, AppNetwork, AppLoading, AppReviewDialog, AdController, TutorialDialog },
    computed: {
      style() {
        // for native map transparent
        const theme = this.isThemeDark ? 'dark' : 'light'
        return this.pageId === 'home' ? {
          'background-color': 'transparent'
        } : {
          'background-color': INIT.COLOR[theme].background
        }
      }
    }
  }
</script>

<style lang="scss">
  :root {
    --safe-area-top: 0px;
    --safe-area-right: 0px;
    --safe-area-bottom: 0px;
    --safe-area-left: 0px;
  }
  // iosの文字列選択をdisableに
  :root {
    user-select: none;
  }
  // 大画面時のダイアログサイズ制限
  .v-dialog:not(.v-dialog--fullscreen) {
    max-width: 400px !important;
  }
  // 全画面ダイアログの角丸
  .v-dialog--fullscreen > .v-card {
    border-radius: 0 !important;
  }
  // タブ高さ
  .v-tabs-items,
  .v-window__container,
  .v-window-item {
    min-height: var(--body-height); // v-mainを固定しているのでここは可変
  }
  // コンテンツの最大幅
  .container:not(.container--fluid) {
    max-width: 800px;
  }
  // 文章コンテンツ
  .container.text {
    padding: 24px 4%;
  }
  // 背景色（タブ＆ダイアログ）
  .v-application .v-main .v-tabs-items,
  .v-main .v-list,
  .v-dialog .v-list {
    background-color: transparent;
  }
  // 背景色（ダイアログ）
  .v-dialog .v-card {
    &.theme--light {
      background-color: #ffffff;
    }
    &.theme--dark {
      background-color: #121212;
    }
  }
  // ボタンクリック後の色変化
  .v-btn:before {
    opacity: 0 !important;
  }
</style>

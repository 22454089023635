// map mixin
import { Geolocation } from '@capacitor/geolocation'
import { Motion } from '@capacitor/motion'
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics"

class Geo {
  constructor() {
    this.motion = null
    this.watchId = null
    this.location = {position: {}, heading: 0, timestamp: 0, isFirst: true}
  }
  // 現在置取得
  async get() {
    const location = await Geolocation.getCurrentPosition().catch(err => { console.log(err) })
    return location?.coords ? {lat: location.coords.latitude, lng: location.coords.longitude} : null
  }
  // 現在値追跡
  async watch(options, callback) {
    options = _.assign({
      enableHighAccuracy: true
    }, options)
    let isFirst = true
    // motion
    try {
      await DeviceMotionEvent.requestPermission()
    } catch (e) {
      console.log(e)
    }
    if (this.motion) { this.motion.remove() }
    this.motion = await Motion.addListener('orientation', event => {
      this.location.heading = 360 - event.alpha
      if (this.location.timestamp) {
        callback(this.location)
      }
    })
    // geolocation
    if (this.watchId) { await Geolocation.clearWatch(this.watchId) }
    this.watchId = await Geolocation.watchPosition(options, (location, err) => {
      if (location?.coords) {
        this.location = {
          position: {lat: location.coords.latitude, lng: location.coords.longitude},
          heading: this.location.heading,
          timestamp: location.timestamp,
          isFirst: isFirst,
        }
        isFirst = false
        callback(this.location)
      } else if (err) {
        console.log(err)
        callback(null)
      }
    })
    // analytics
    FirebaseAnalytics.logEvent({
      name: 'located',
      params: {located: 'true'},
    })
  }
  async stopWatch() {
    if (this.motion) {
      this.motion.remove()
      this.motion = null
    }
    if (this.watchId) {
      await Geolocation.clearWatch({id: this.watchId})
      this.watchId = null
    }
  }
}

export default new Geo()

import 'leaflet/dist/leaflet.css'
import L from 'leaflet'
import 'leaflet-marker-rotation'
import _leaflet from '@/commons/modules/_leaflet.js'
import GSI_LAYERS from '@/jsons/layers.json'

export default L

// L拡張
L.draw = options => {
  options = Object.assign({
    id: 'map', zoom: 15, center: {lat: 35.7052579, lng: 139.752536},
    attributionControl: false, zoomControl: false,
    tapHold: true, // contextmenuとかぶってない？
    inertia: false, // 慣性
    preferCanvas: true, // canvasで描画
  }, options)
  const map = L.map(document.getElementById(options.id), options)
  const tileLayer = null
  return _.assign(map, customMethods(map, tileLayer))
}


// map拡張
const customMethods = (map, tileLayer) => {

  _leaflet.map = map
  _leaflet.tileLayer = tileLayer

  return _.assign({}, _leaflet, {
  
    // マーカー追加
    addMarker(position, icon, angle) {
      if (! this.map) { return } else {
        return L.rotatedMarker(position, {
          icon: L.icon(icon),
          rotationAngle: angle,
          rotationOrigin: 'center center'
        }).addTo(this.map)
      }
    },

    // selected marker追加
    addSelectedMarker(position) {
      if (! this.map) { return }
      const iconUrl = require('@/assets/map-marker-circle.svg')
      return this.addMarker(position, {
        iconUrl: iconUrl,
        iconSize: [26, 26],
        iconAnchor: [13, 13],
        popupAnchor: [0, -13],
      })
    },

    // favorites markers 追加
    addFavoriteMarker(position, clickFunc) {
      if (! this.map) { return }
      const iconUrl = require('@/assets/map-marker.svg')
      return this.addMarker(position, {
        iconUrl: iconUrl,
        iconSize: [30, 60],
        iconAnchor: [15, 45],
        popupAnchor: [0, -30],
      }).on('click', clickFunc)
    },

    // 年代変更
    addGSILayer(age, callbackId, callback) {
      callbackId = callbackId || null
      callback = callback || function() {}
      if (! this.map) { return }
      const layerInfo = _.find(GSI_LAYERS, layer => { return layer.age === age })
      const tiles = new L.GridLayer({
        maxNativeZoom: 17,
        minNativeZoom: 10
      })
      // 現在のレイヤー群を削除
      this.map.eachLayer(layer => { return this.map.removeLayer(layer) })
      // タイル画像読み込み
      tiles.createTile = coords => {
        const img = L.DomUtil.create('img')
        img.src = 'https://cyberjapandata.gsi.go.jp/xyz/' + layerInfo.dir + '/' + coords.z + '/' + coords.x + '/' + coords.y + '.' + layerInfo.ext
        // 読み込みエラー時
        img.onerror = () => {
          img.onerror = null
          img.src = require('@/assets/nodata.png')
        }
        return img
      }
      tiles.addTo(this.map)
      if (callbackId) {
        tiles.on('load', callback(callbackId))
      }
    },

  })
}

<template>
  <fragment>
    <div id="map2"></div>
    <Map4 />
  </fragment>
</template>

<script>
  import GoogleMap from '@/commons/modules/googleMaps.js'
  import Map4 from '@/components/Map4.vue'
  export default {
    components: { Map4 },
    data() {
      return {
        mapId: 'map2',
        map: new GoogleMap(),
      }
    },
    async mounted() {
      setTimeout(async () => {
        await this.map.draw({
          id: this.mapId,
          center: this.currentCenter,
          zoom: this.currentZoom,
          mapTypeId: this.style,
          disableDefaultUI: true
        })
      }, 0)
    },
    watch: {
      // 地図移動
      currentCenter() {
        this.map.panTo(this.currentCenter)
      },
      // ズーム変更
      currentZoom() {
        this.map.setZoom(this.currentZoom)
      },
      // マップタイプ
      style() {
        this.map.setMapType(this.style)
      },
    },
  }
</script>

<style lang="scss" scoped>
  #map2 {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
</style>

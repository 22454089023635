<template>
  <fragment>
    <div id="map3"></div>
    <Map4 />
  </fragment>
</template>

<script>
  import { ResizeObserver } from 'resize-observer'
  import NativeMaps from '@/commons/modules/nativeMaps.js'
  import Map4 from '@/components/Map4.vue'

  export default {
    components: { Map4 },
    data() {
      return {
        mapId: 'map3',
        map: new NativeMaps(),
        resizeTimeout: null,
      }
    },
    async mounted() {
      setTimeout(async () => {
        await this.map.draw({
          id: this.mapId,
          center: this.currentCenter,
          zoom: this.currentZoom,
          style: this.style,
        })
        // resizeイベント
        new ResizeObserver(() => {
          if (document.getElementById('map3')) { // 別ページ表示時のエラー対策
            clearTimeout(this.resizeTimeout)
            this.resizeTimeout = setTimeout(() => {
              this.map.refresh({
                id: this.mapId,
                center: this.currentCenter,
                zoom: this.currentZoom,
                style: this.style,
              })
              this.nativeMapRefreshed = this.$dayjs().valueOf()
            }, 500)
          }
        }).observe(document.getElementById('map3'))
      }, 500) // for ios
    },
    watch: {
      // 地図移動
      currentCenter() {
        this.map.panTo(this.currentCenter)
      },
      currentZoom() {
        this.map.setZoom(this.currentZoom)
      },
      // マップタイプ
      style() {
        this.map.setMapType(this.style)
        setTimeout(() => {
          this.nativeMapRefreshed = this.$dayjs().valueOf()
        }, 500)
      },
    },
  }
</script>

<style lang="scss" scoped>
  #map3 {
    height: 100%;
    width: 100%;
  }
</style>

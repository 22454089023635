<template>
  <v-dialog
    v-model="dialog"
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <p>{{ $t('desc1') }}</p>
        <p>{{ $t('desc2') }}</p>
        <v-btn
          block
          color="primary"
          x-large
          @click="toStore"
        >
          <div>{{ $t('button') }}</div>
          <div>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
            <v-icon color="rgba(255,255,255,.6)">star</v-icon>
          </div>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          @click="dialog = false"
        >{{ $t('dialog.close') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default{
    data() {
      return {
        dialog: false
      }
    },
    async mounted() {
      if (this.isApp && ! this.isReviewed && this.storage.launches.length === 3) {
        setTimeout(() => {
          this.dialog = true
          this.isReviewed = true
        }, 2000)
      }
    },
    methods: {
      toStore() {
        this.dialog = false
        const scheme = (this.userAgent === 'android') ? this.INIT.URLS.play : this.INIT.URLS.app
        window.open(scheme, '_system')
      },
    },
    i18n: {
      messages: {
        ja: {
          title: '評価のお願い',
          desc1: 'アプリのご利用、ありがとうございます。',
          desc2: 'もしこのアプリを気に入ったいただけたら、評価をしていただけるとアプリ開発の励みにもなります。',
          button: '評価して応援する'
        },
        en: {
          title: 'REVIEW',
          desc1: 'Thank you for using this app.',
          desc2: 'If you like this app, please rate it, it will encourage me to develop this app.',
          button: 'Rate it to support us'
        }
      }
    },
  }
</script>
<style lang="scss" scoped>
  .v-dialog__content {
    ::v-deep .v-dialog {
      margin-bottom: 120px;
    }
  }
  .v-btn {
    height: auto !important;
    padding: 10px 0 !important;
    ::v-deep .v-btn__content {
      flex-direction: column;
    }
  }
</style>

<template>
  <v-app-bar
    app
    :height="appBarHeight"
  >
    <v-btn
      icon
      v-if="! isLargeScreen || ! INIT.MENU.pcOpen"
      @click.stop="toggleDrawer"
    ><v-icon>menu</v-icon></v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer />
    <slot></slot>
  </v-app-bar>
</template>

<script>
  export default {
    props: ['title'],
    data() {
      return {}
    },
    computed: {},
    mounted() {},
    methods: {
      toggleDrawer() {
        this.drawer = ! this.drawer
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-toolbar {
    ::v-deep .v-toolbar__content {
      padding-top: var(--safe-area-top);
    }
  }
</style>
<template>
  <div></div>
</template>

<script>
  import { Capacitor } from '@capacitor/core'
  export default {
    data() {
      return {
        moutedTimestamp: this.$dayjs().valueOf(),
        interstitialWait: 5 * 60 * 1000, // 5分間は表示させない
        interstitialRatio: 2, // 2回に１回
        interstitialSleep: 20 * 60 * 1000, // 20分間は表示させない
        nextInterstitial: 0,
        waitForValidate: (Capacitor.getPlatform() === 'ios') ? 5000 : 1000, // アプリ開始時にvalidateを待つ（要検討）
        isReady: false, // ready for banner
      }
    },
    mounted() {
      setTimeout(() => {
        this.isReady = true
        this.banner = true
      }, this.waitForValidate)
    },
    watch: {
      pageId() {
        if (this.pageId === 'home') {
          this.tryInterstitial()
        }
      },
      searchFocused() {
        if (! this.searchFocused && ! this.searchQuery && ! this.searchKeyword) {
          this.tryInterstitial()
        }
      },
      age() {
        if (this.age === 76) {
          this.tryInterstitial()
        }
      },
      favorite(after, before) {
        // interstitial
        if(! after && before) {
          this.tryInterstitial()
        }
        // banner
        if (after) {
          this.banner = false
        } else if (! after && before) {
          if (this.isReady) {
            this.banner = true
          }
        }
      },
      nativeMapRefreshed() {
        // androidでnativeMap更新時に消える不具合対策
        if (this.platform === 'android') {
          this.banner = false
          setTimeout(() => {
            if (this.targetPosition.x % 50 === 0 && this.targetPosition.y % 50 === 0 && this.isReady) {
              this.banner = true
            }
          }, 1000)
        }
      },
    },
    methods: {
      tryInterstitial() {
        console.log('tryed')
        if (this.moutedTimestamp + this.interstitialWait < this.$dayjs().valueOf()) {
          if (this.nextInterstitial < this.$dayjs().valueOf()) {
            if (_.random(this.interstitialRatio - 1) === 0) {
              this.interstitial = true
              this.nextInterstitial = this.$dayjs().valueOf() + this.interstitialSleep
            }
          }
        }
      }
    }
  }
</script>


<template>
  <fragment>
    <TheAppbar :title="$t('title')" />
    <v-main :style="{minHeight: this.bodyHeight + 'px'}">
      <v-container class="text">
        <h2>{{ $t('purchaseHead') }}</h2>
        <p>{{ $t('purchaseDesc') }}<br>
          <small class="warning--text" v-if="isPurchased">{{ $t('purchaseAlready') }}</small>
        </p>
        <v-list v-if="tickets">
          <v-list-item
            v-for="ticket in activeTickets"
            :key="ticket.id"
          >
            <v-list-item-content>{{ ticket.name[lang] }}</v-list-item-content>
            <v-list-item-action>
              <v-btn
                class="title"
                large
                color="primary"
                :disabled="isPurchased"
                @click="order(ticket.id)"
              >{{ ticket.price }} / {{ ticket.period[lang] }}</v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="platform === 'ios'">
            <v-list-item-content>
              <v-btn
                class="title"
                block
                large
                :dark="! isPurchased"
                :disabled="isPurchased"
                @click="restore"
              >{{ $t('restore') }}</v-btn>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <h2>{{ $t('cancelHead') }}</h2>
        <p v-html="platform === 'ios' ? $t('cancelDescIos') : $t('cancelDescAndroid')"></p>
      </v-container>
    </v-main>

    <!-- 復元完了 -->
    <v-snackbar
      v-model="snackbar"
      timeout="5000"
    >
      <span v-if="isPurchased">{{ $t('restoreSuccess') }}</span>
      <span v-else>{{ $t('restoreFail') }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >{{ $t('dialog.close') }}</v-btn>
      </template>
    </v-snackbar>
  </fragment>
</template>

<script>
  import TheAppbar from '@/commons/components/TheAppbar.vue'
  import Purchase from '@/commons/modules/purchase.js'

  export default {
    components: { TheAppbar },
    data() {
      return {
        snackbar: false,
        restoreTimeout: null,
      }
    },
    computed: {
      activeTickets() { return _.filter(this.tickets, ['active', true]) }
    },
    methods: {
      order(id) {
        Purchase.order(id)
      },
      restore() {
        this.isLoadings = true
        Purchase.restore()
        clearTimeout(this.restoreTimeout)
        this.restoreTimeout = setTimeout(() => {
          this.snackbar = true
          this.isLoadings = false
          this.restoreTimeout = null
        }, 10000)
      }
    },
    watch: {
      isPurchased() {
        // loadingをキャンセル
        if (this.isPurchased) {
          if (this.restoreTimeout) {
            clearTimeout(this.restoreTimeout)
            this.snackbar = true
            this.isLoadings = false
            this.restoreTimeout = null
          }
        }
      }
    },
    i18n: {
      messages: {
        ja: {
          title: '広告非表示のご案内',
          purchaseHead: '広告非表示（定期購入）の申し込み',
          purchaseDesc: '広告非表示をご希望の方は、以下のチケットをご購入下さい。',
          purchaseAlready: '※購入済みです',
          cancelHead: '定期購入の解除',
          cancelDescAndroid: '上記の金額は、自動的に更新・支払いが行われます。定期購入の解除は、<strong>「Playストア」</strong>アプリの<strong>「定期購入」</strong>のページより行って下さい。<br><small class="caution">※購入後すぐに解除を行うことも可能です。その場合にも、残りの期間広告は非表示になります。</small>',
          cancelDescIos: '上記の金額は、自動的に更新・支払いが行われます。定期購入の解除は、<strong>「設定」</strong>の<strong>「自分の名前」</strong>をタップし、<strong>「サブスクリプション」</strong>メニューより行って下さい。<br><small class="caution">※購入後すぐに解除を行うことも可能です。その場合にも、残りの期間広告は非表示になります。</small>',
          restore: '購入の復元',
          restoreSuccess: '購入の復元が完了しました',
          restoreFail: '復元する購入はありません',
        },
        en: {
          title: 'PURCHASE',
          purchaseHead: 'Subscriptions',
          purchaseDesc: 'Subscriptions to remove advertisement.',
          purchaseAlready: '* Already purchased',
          cancelHead: 'Cancel',
          cancelDescAndroid: 'The above amount is automatically updated / paid.<br>To cancel the subscription, please go <strong>"Google Play"</strong> application. <br><small class="caution">* Even if you cancel the purchase immediately, the advertisement will be removed for the remainder of the term.</small>',
          cancelDescIos: 'The above amount is automatically updated / paid.<br>To cancel the subscription, please go <strong>"Settings"</strong> menu. <br><small class="caution">* Even if you cancel the purchase immediately, the advertisement will be removed for the remainder of the term.</small>',
          restore: 'Restore Purchase',
          restoreSuccess: 'Successfully restored.',
          restoreFail: 'No purchase to restore.',
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  h2 {
    margin: 12px 0;
  }
  .price {
    font-size: 1.6em;
  }
</style>

<template>
  <fragment>
    <transition name="fade">
      <v-btn
        fab absolute
        bottom right
        :ripple="false"
        :loading="isLocating"
        v-show="showUI"
        @click="isWatching ? stopWatchPosition() : watchPosition()"
        :class="{'low-height': bodyHeight < 576}"
      ><v-icon :color="isWatching ? 'primary' : null">{{ isLocated ? 'my_location' : 'gps_not_fixed' }}</v-icon></v-btn>
    </transition>

    <v-snackbar
      v-model="snackbar"
      top
      timeout="5000"
    >
      {{ $t('map.locationFailed') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >{{ $t('dialog.close') }}</v-btn>
      </template>
    </v-snackbar>

  </fragment>
</template>

<script>
  import Geolocation from '@/commons/modules/geolocation.js'
  export default{
    data() {
      return {
        snackbar: false,
        isLocated: false,
        isLocating: false,
        isWatching: false,
        watchingTimeout: null,
      };
    },
    watch: {
      currentCenter() {
        if (
          this.currentLocation.timestamp > this.$dayjs().valueOf() - (60 * 1000)
          && Math.abs(this.currentCenter.lat - this.currentLocation.position.lat) < 0.0001
          && Math.abs(this.currentCenter.lng - this.currentLocation.position.lng) < 0.0001
        ) {
          this.isLocated = true
        } else {
          this.isLocated = false
        }
      }
    },
    methods: {
      // 現在地の取得
      async watchPosition() {
        this.isLocating = true
        this.isLocated = false
        this.isWatching = false
        Geolocation.watch({}, location => {
          if (location) {
            this.currentLocation = _.cloneDeep(location)
            if (location.isFirst) {
              // 初回
              this.primaryMap = null
              this.currentCenter = this.currentLocation.position
              this.isLocated = true
            }
            this.renewIsWatching()
          } else {
            this.snackbar = true
          }
          this.isLocating = false
        })
      },
      // 現在地取得中の状態管理
      // 60s現在地の更新がない場合にはストップ
      renewIsWatching() {
        this.isWatching = true
        clearTimeout(this.watchingTimeout)
        this.watchingTimeout = setTimeout(() => {
          this.stopWatchPosition()
        }, 60 * 1000)
      },
      // 現在地の取得中止
      async stopWatchPosition() {
        this.isWatching = false
        this.currentLocation = {}
        await Geolocation.stopWatch()
      }
    },
  }
</script>

<style scoped lang="scss">
  .v-btn--fab {
    bottom: 16px !important;
    &.low-height {
      right: calc(16px + var(--safe-area-right));
    }
  }
  /* 全画面サイズなのでポインター透過 */
  .v-snack {
    pointer-events: none !important;
    top: calc(var(--safe-area-top) + 80px);
  }
</style>

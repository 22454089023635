<template>
  <fragment>
    <transition name="fade">
      <v-toolbar
        elevation="0"
        v-show="showUI"
        color="transparent"
        height="48px"
        max-width="500px"
        :class="{'low-height': bodyHeight < 576}"
      >
        <v-btn
          icon
          v-if="! searchFocused"
          @click.stop="drawer = true"
        ><v-icon>menu</v-icon></v-btn>
        <v-btn
          icon
          v-else
          @click="searchFocused = false"
        ><v-icon>chevron_left</v-icon></v-btn>
        <v-text-field
          id="search-input"
          ref="search"
          hide-details
          clearable
          solo
          :append-icon="searchKeyword ? '' : 'search'"
          clear-icon="close"
          :placeholder="$t('placeholer')"
          v-model="searchKeyword"
          @click="click"
          @click:clear="click"
          @compositionstart="compositionstart"
          @compositionend="compositionend"
          @keydown.enter="compositionend"
        ></v-text-field>
      </v-toolbar>
    </transition>
    <ToolbarDialog />
  </fragment>
</template>

<script>
  import ToolbarDialog from '@/components/UIsToolbarDialog.vue'

  export default {
    components: { ToolbarDialog },
    data() {
      return {
        isComposing: false,
      }
    },
    watch: {
      searchKeyword() {
        // clear処理
        if (! this.searchKeyword) {
          this.searchQuery = ''
        }
      },
      searchFocused() {
        if (! this.searchFocused) {
          setTimeout(() => {
            document.getElementById('search-input').blur()
          }, 0)
        }
      }
    },
    methods: {
      click() {
        this.searchFocused = true
        this.primaryMap = null
      },
      compositionstart() {
        this.isComposing = true
      },
      compositionend() {
        // 入力確定時
        this.isComposing = false
        this.searchQuery = this.searchKeyword
      },
    },
    i18n: {
      messages: {
        ja: {
          placeholer: '検索できます',
        },
        en: {
          placeholer: 'Search map...',
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  .v-toolbar {
    transition: background-color .1s;
    z-index: 300;
    &.low-height {
      margin: 0 auto;
    }
    ::v-deep .v-toolbar__content {
      margin: 0 12px;
      width: calc(100% - 24px);
      padding: 0 16px;
      background-color: var(--v-background-base);
      border-radius: 100px;
      box-shadow: 0 3px 3px 1px rgba(0,0,0,.15) !important;
      overflow: hidden;
    }
  }
  .v-input {
    ::v-deep .v-input__slot {
      background-color: var(--v-background-base) !important;
      box-shadow: none !important;
    }
  }
</style>

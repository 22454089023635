/* global store */
import INIT from '@/init.js'
import { Capacitor } from '@capacitor/core'

const TICKETS = INIT.TICKETS[Capacitor.getPlatform()]

class Purchase {
  constructor() {
    this.tickets = null
  }
  async initialize(getCallback, purchaseCallback) {
    if (! this.tickets) {
      if (Capacitor.getPlatform() === 'ios') {
        store.validator = 'https://map70.aroundit.net/api/validate/'
        // store.validator = 'http://192.168.11.18:5001/map70-201610/us-central1/api/validate/'
        // store.validator = 'https://validator.fovea.cc/v1/validate?appName=net.aroundit.test&apiKey=2a6eaba1-f9df-4c7e-a4bf-f154cee733e4'
      }
      this.register()
      this.getTickets(getCallback)
      this.watch(purchaseCallback)
      store.refresh()
    }
  }
  // チケット登録
  register() {
    _.forEach(TICKETS, ticket => {
      store.register({
        id: ticket.id,
        type: store.PAID_SUBSCRIPTION
      })
    })
  }
  // チケット取得
  getTickets(callback) {
    store.ready(() => {
      if (store.products && store.products.length) {
        this.tickets = _.compact(_.map(store.products, product => {
          const info = _.find(TICKETS, ['id', product.id])
          return info ? _.assign(_.cloneDeep(product), info) : null
        }))
      }
      // console.log(JSON.stringify(this.tickets))
      callback(this.tickets)
    })
  }
  // 購入監視
  watch(callback) {
    _.forEach(TICKETS, ticket => {
      store.when(ticket.id)
      .approved(product => {
        console.log(product.id + ': approved')
        product.verify()
      })
      .verified(product => {
        console.log(product.id + ': verified')
        product.finish()
      })
      .owned(() => {
        console.log(ticket.id + ': owened')
        callback(ticket.id)
      })
      .expired(() => {
        console.log(ticket.id + ': expired')
      })
    })
  }
  // 注文
  order(id) {
    store.order(id)
  }
  // Restore (ios only)
  restore() {
    store.refresh()
  }
}

export default new Purchase()

<template>
  <fragment>

    <v-main>
      <Maps />
      <MapUIs />
      <v-tabs-items
        v-model="tabId"
        touchless
      >
        <!-- パフォーマンス優先でv-if -->
        <v-tab-item value="tab0"><Tab0 v-if="tabId === 'tab0'" /></v-tab-item>
        <v-tab-item value="tab1"><Tab1 v-if="tabId === 'tab1'" /></v-tab-item>
        <v-tab-item value="tab2"><Tab2 v-if="tabId === 'tab2'" /></v-tab-item>
        <v-tab-item value="tab3"><Tab3 v-if="tabId === 'tab3'" /></v-tab-item>
      </v-tabs-items>
    </v-main>

    <TheFooter
      :tabItems="currentTabs"
    ></TheFooter>

    <DialogFavorite />

    <div class="banner" v-if="isSite">
      <a v-if="userAgent === 'android'" :href="INIT.URLS.play" target="_blank">
        <img :src="require('@/assets/google-play.png')" :alt="$t( 'download.play' )">
      </a>
      <a v-if="userAgent === 'ios'" :href="INIT.URLS.app" target="_blank">
        <img :src="require('@/assets/app-store.svg')" :alt="$t( 'download.app' )">
      </a>
    </div>

  </fragment>
</template>

<script>
  import Tab0 from '@/views/Tab0.vue'
  import Tab1 from '@/views/Tab1.vue'
  import Tab2 from '@/views/Tab2.vue'
  import Tab3 from '@/views/Tab3.vue'
  import Maps from '@/components/Maps.vue'
  import MapUIs from '@/components/UIs.vue'
  import DialogFavorite from '@/components/DialogFavorite.vue'
  import TheFooter from '@/commons/components/TheFooter.vue'
  import Firestore from '@/commons/modules/firestore.js'
  import { mdiTwitter } from '@mdi/js'

  export default {
    components: { Maps, MapUIs, Tab0, Tab1, Tab2, Tab3, DialogFavorite, TheFooter },
    data() {
      return {
        tabItems: [{
          id: 'tab0',
          title: {ja: '地図', en: 'map'},
          icon: 'map',
        }, {
          id: 'tab1',
          title: {ja: '保存済み', en: 'saved'},
          icon: 'bookmark_border',
        // }, {
        //   id: 'tab2',
        //   title: {ja: '投稿', en: 'post'},
        //   icon: 'add_circle_outline',
        }, {
          id: 'tab2',
          title: {ja: 'ツイート', en: 'tweet'},
          icon: mdiTwitter,
        }, {
          id: 'tab3',
          title: {ja: '設定', en: 'menu'},
          icon: 'more_vert',
        }],
      }
    },
    computed: {
      currentTabs() {
        return (this.isSite || ! this.showUI) ? null : this.tabItems
      },
    },
    async created() {
      // site
      if (this.isSite) {
        this.showUI = false
      }
      // tweet取得
      const res = await Firestore.getDoc('caches', 'tweets_latest')
      this.tweets = res ? res.tweets : null
    },
    async mounted() {
      // パラメータあり
      if (this.urlParam) {
        const res = await Firestore.getDoc('shares', this.urlParam)
        if (res) {
          this.currentCenter = res.position
          this.currentZoom = res.zoom
          this.age = res.age
          this.urlParam = null
        }
      }
    },
    watch: {
      // 選択中地点解除
      tabId() {
        if (this.tabId !== 'tab0') {
          this.selectedPlace = null
        }
      },
    },
  }
</script>

<style lang="scss">
  // browserでの表示崩れ対策
  body {
    // height: var(--body-height);
    min-height: var(--body-height);
  }
  .v-application,
  .v-application--wrap {
    height: 100% !important;
    min-height: 100% !important;
  }
</style>

<style lang="scss" scoped>
  .v-main {
    height: var(--body-height); // 固定
  }
  .v-tabs-items {
    pointer-events: none;
    position: relative;
    z-index: 1;
  }
  .v-window-item {
    display: flex;
    &#tab0 {
      align-items: flex-end;
    }
    &:not(#tab0) {
      background-color: var(--v-background-base);
    }
    ::v-deep .container {
      display: block;
      pointer-events: all;
    }
  }
  .banner {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    text-align: center;
    img {
      height: 60px;
      width: auto;
    }
  }
</style>

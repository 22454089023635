<template>
  <v-dialog v-model="dialog" eager>
    <v-card>
      <v-card-title>{{ input.title }}</v-card-title>
      <!-- text -->
      <v-card-text v-if="input.type === 'text'">
        <v-text-field
          v-model="value"
          label=""
        ></v-text-field>
      </v-card-text>
      <!-- radio -->
      <v-card-text v-if="input.type === 'radio'">
        <v-radio-group v-model="value">
          <v-radio
            v-for="option in input.options"
            :key="option.id"
            :label="option.label"
            :value="option.id"
            on-icon="radio_button_checked"
            off-icon="radio_button_unchecked"
            @click="save"
          ></v-radio>
        </v-radio-group>
      </v-card-text>
      <!-- combobox -->
      <v-card-text v-if="input.type === 'combobox'">
        <v-combobox
          v-model="value"
          :items="input.options"
          label=""
          multiple
          chips
        ></v-combobox>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="dialog = null"
        >{{ $t('dialog.no') }}</v-btn>
        <v-btn
          text
          color="primary"
          @click="save"
          v-if="input.type === 'text' || input.type === 'combobox'"
        >{{ $t('dialog.yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: ['inputDialog'],
    data() {
      return {
        value: null,
      }
    },
    computed: {
      dialog: {
        get() { return this.inputDialog ? true : false },
        set() { this.$emit('cancel') }
      },
      input() {
        return this.inputDialog || {
          type: 'radio',
          title: null,
          options: [{id: 'dummy', label: 'dummy'}],
        }
      }
    },
    watch: {
      inputDialog() {
        this.value = this.inputDialog?.value || null
      },
    },
    methods: {
      save() {
        this.$emit('save', {key: this.inputDialog.key, value: this.value})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .v-card__text {
    padding-bottom: 0 !important;
  }
  .v-radio {
    margin: 0 0 24px 20px !important;
  }
  .v-input {
    ::v-deep .v-messages {
      display: none !important;
    }
  }
</style>

// global
import 'lodash'

// import
import 'material-icons/iconfont/material-icons.css' // 両方読み込む
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/modules/router'
import Store from '@/modules/store'
import vuetify from '@/commons/modules/vuetify'
import i18n from '@/modules/i18n'
import '@/modules/mixin'
import { Capacitor } from '@capacitor/core'
import Analytics from '@/commons/modules/analytics.js'

// plugins
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

// dayjs
import dayjs from 'dayjs'
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)
import 'dayjs/locale/ja'
dayjs.locale('ja')
Vue.prototype.$dayjs = dayjs

// fragment（enable multi root component）
// vue-fragment@1.5.1
import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

// status bar for fullscreen
import {StatusBar, Style} from '@capacitor/status-bar'

new Vue({
  router,
  store: Store,
  vuetify,
  i18n,
  render: h => h(App),
  created() {},
  async mounted() {
    document.addEventListener('deviceready', () => {
      // analytics
      setTimeout(() => {
        Analytics.setUserProperties([
          {name: 'favorite_count', value: this.favorites.length}, // 0がエラーの可能性（'0'とすべき？）
          {name: 'split', value: this.split},
          {name: 'style', value: this.style},
          {name: 'crosshair', value: this.crosshair ? 'true' : 'false'}
        ])
      }, 5000)
    })
  },
  computed: {
    _renewStatusBar() { return this.pageId + this.tabId + this.favorite + this.searchFocused }
  },
  watch: {
    _renewStatusBar: {
      immediate: true,
      handler() {
        setTimeout(() => {
          if (Capacitor.getPlatform() !== 'web') {
            StatusBar.setOverlaysWebView({ overlay: true })
            if (this.pageId === 'home' && this.tabId === 'tab0' && ! this.favorite && ! this.searchFocused) {
              StatusBar.setStyle({ style: Style.Dark })
            } else {
              StatusBar.setStyle({ style: this.isThemeDark ? Style.Dark : Style.Light })
            }
          }
        }, 0)
      }
    },
  }
}).$mount('#app')

Vue.config.productionTip = false
